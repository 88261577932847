import React from "react";
import { Link } from "react-router-dom";
import { LangContext } from "../../../languageContext";
import "./interiors-detail.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const slides = [
  {
    id: 1,
    width: 4,
    height: 3,
  },
  {
    id: 2,
    width: 4,
    height: 3,
  },
  {
    id: 3,
    width: 1,
    height: 1,
  },
  {
    id: 4,
    width: 3,
    height: 4,
  },
];

export default class InteriorsDetail extends React.Component {
  static contextType = LangContext;

  settings = {
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    arrows: false,
    afterChange: (index) => {
      this.setState({ currentSlide: index });
    },
  };

  constructor(props) {
    super(props);

    this.state = {
      slug: this.props.match.params.slug,
      allData: null,
      data: null,
      currentSlide: 0,
      currentImage: 0,
      viewerIsOpen: false,
      imagesAdded: false,
      procData: null,
    };
  }

  openLightBox = (event, { photo, index }) => {
    this.setState({
      currentImage: index,
      viewerIsOpen: true,
    });
  };

  closeLightBox = () => {
    this.setState({
      viewerIsOpen: false,
    });
  };

  componentDidMount = () => {
    this.loadData();
  };

  componentDidUpdate = () => {
    const slug = this.props.match.params.slug;
    if (this.state.slug !== slug) {
      this.setState({ slug }, this.displayDetail);
    }
  };

  async loadData() {
    try {
      const response = await fetch("https://n1.noxtton.com/motif-interiors", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-api-key": "e17fd67932e135afbe858ad03882d826feaf0124e972d08d",
        },
      });
      const json = await response.json();

      if (json) {
        this.setState({ allData: json }, this.displayDetail);
      }
    } catch (err) {
      console.log(err);
    }
  }

  displayDetail = () => {
    if (this.state.allData) {
      const json = this.state.allData;

      const key = Object.keys(json).find(
        (item) =>
          json[item].en.coverTitle.trim().replace(/\s/g, "").toUpperCase() ===
          this.state.slug.toUpperCase()
      );

      if (json[key]) {
        this.setState({ data: json[key] }, this.createSlides);
      }
    }
  };

  createSlides = () => {
    if (this.state.data) {
      let data = this.state.data.en;
      slides[0].src = data?.gallery1Full;
      slides[1].src = data?.gallery2Full;
      slides[2].src = data?.gallery3Full;
      slides[3].src = data?.gallery4Full;
    }
    this.setState({ imagesAdded: true });
  };

  createMarkup = (val) => {
    return { __html: val };
  };

  render() {
    let data = this.state.data ? this.state.data[this.context.language] : null;

    return (
      <div className="interiors-detail">
        <div className="interiors-detail__inner">
          <div className="interiors-detail__hat">
            <div
              className="interiors-detail__hatimg"
              style={{
                backgroundImage: `url(${
                  this.state.data &&
                  this.state.data.en &&
                  this.state.data?.en.coverImg
                })`,
              }}
            ></div>
            <div
              className="interiors-detail__hat-overlay"
              style={{
                display: "block",
                opacity: 0.4
              }}
            ></div>
          </div>

          <div className="interiors-detail__text">
            <div>{data?.coverTitle}</div>
            <div
              dangerouslySetInnerHTML={this.createMarkup(data?.coverSubtitle)}
              className="interiors-detail__cover-subtitle"
            ></div>
          </div>

          <div className="site-path">
            <span>
              <Link to="/">
                {this.context.language === "en" ? "Home" : "მთავარი გვერდი"}
              </Link>{" "}
              /{" "}
              <Link to="/interiors">
                {this.context.language === "en" ? "Interiors" : "ინტერიერი"}
              </Link>{" "}
              / {data?.coverTitle}
            </span>
          </div>

          <div className="interiors-detail__midsection">
            <div className="interiors-detail__midsection-product">
              <div
                className="interiors-detail__midsection-img"
                style={{
                  backgroundImage: `url(${
                    this.state.data &&
                    this.state.data.en &&
                    this.state.data?.en.interiorHeroBlockImg
                  })`,
                }}
              ></div>
              <div className="interiors-detail__midsection-productname">
                <div>{data?.interiorHeroBlockTitle}</div>
                <div>{data?.interiorHeroBlockSubtitle}</div>
              </div>
            </div>
            <div
              className="interiors-detail__midsection-text"
              dangerouslySetInnerHTML={this.createMarkup(
                data?.interiorHeroBlockText
              )}
            ></div>
          </div>

          <div className="interiors-detail__gallery">
            {/* THIS ONLY SHOWS IN MOBILE */}
            <div className="interiors-detail__inner-gallery">
              {this.state.imagesAdded ? (
                <>
                  <Slider {...this.settings}>
                    {slides?.map((item) => (
                      <React.Fragment key={item.id}>
                        <div
                          className="interiors-detail__gallery-item"
                          style={{ backgroundImage: `url('${item?.src}')` }}
                        ></div>
                      </React.Fragment>
                    ))}
                  </Slider>
                  <div className="interiors-detail__slides-num">
                    <span>
                      {this.state.currentSlide + 1} / {slides.length}
                    </span>
                  </div>
                </>
              ) : null}
            </div>
            {/* THIS ONLY SHOWS IN MOBILE */}

            {/* THIS ONLY SHOWS IN DESKTOP */}
            <div className="interiors-detail__inner-gallery-web">
              <div className="interiors-detail__upper-gallery">
                <div>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={
                      this.context.language === "en"
                        ? this.state.data &&
                          this.state.data.en &&
                          this.state.data?.en.gallery1Link
                        : this.state.data &&
                          this.state.data.ka &&
                          this.state.data?.ka.gallery1Link
                        ? this.state.data?.ka.gallery1Link
                        : this.state.data &&
                          this.state.data.en &&
                          this.state.data?.en.gallery1Link
                    }
                  >
                    <div className="interiors-detail__prod1">
                      <div
                        className="interiors-detail__img1"
                        style={{
                          backgroundImage: `url(${
                            this.state.data &&
                            this.state.data.en &&
                            this.state.data?.en.gallery1Full
                          })`,
                        }}
                      ></div>
                      <div>{data?.gallery1Title}</div>
                      <div>{data?.gallery1Subtitle}</div>
                    </div>
                  </a>
                </div>
                <div>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={
                      this.context.language === "en"
                        ? this.state.data &&
                          this.state.data.en &&
                          this.state.data?.en.gallery2Link
                        : this.state.data &&
                          this.state.data.ka &&
                          this.state.data?.ka.gallery2Link
                        ? this.state.data?.ka.gallery2Link
                        : this.state.data &&
                          this.state.data.en &&
                          this.state.data?.en.gallery2Link
                    }
                  >
                    <div className="interiors-detail__prod2">
                      <div
                        className="interiors-detail__img2"
                        style={{
                          backgroundImage: `url(${
                            this.state.data &&
                            this.state.data.en &&
                            this.state.data?.en.gallery2Full
                          })`,
                        }}
                      ></div>
                      <div>{data?.gallery2Title}</div>
                      <div>{data?.gallery2Subtitle}</div>
                    </div>
                  </a>

                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={
                      this.context.language === "en"
                        ? this.state.data &&
                          this.state.data.en &&
                          this.state.data?.en.gallery3Link
                        : this.state.data &&
                          this.state.data.ka &&
                          this.state.data?.ka.gallery3Link
                        ? this.state.data?.ka.gallery3Link
                        : this.state.data &&
                          this.state.data.en &&
                          this.state.data?.en.gallery3Link
                    }
                  >
                    <div className="interiors-detail__prod3">
                      <div
                        className="interiors-detail__img3"
                        style={{
                          backgroundImage: `url(${
                            this.state.data &&
                            this.state.data.en &&
                            this.state.data?.en.gallery3Full
                          })`,
                        }}
                      ></div>
                      <div>{data?.gallery3Title}</div>
                      <div>{data?.gallery3Subtitle}</div>
                    </div>
                  </a>
                </div>
              </div>
              <div className="interiors-detail__lower-gallery">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={
                    this.context.language === "en"
                      ? this.state.data &&
                        this.state.data.en &&
                        this.state.data?.en.gallery4Link
                      : this.state.data &&
                        this.state.data.ka &&
                        this.state.data?.ka.gallery4Link
                      ? this.state.data?.ka.gallery4Link
                      : this.state.data &&
                        this.state.data.en &&
                        this.state.data?.en.gallery4Link
                  }
                >
                  <div className="interiors-detail__prod4">
                    <div
                      className="interiors-detail__img4"
                      style={{
                        backgroundImage: `url(${
                          this.state.data &&
                          this.state.data.en &&
                          this.state.data?.en.gallery4Full
                        })`,
                      }}
                    ></div>
                    <div>{data?.gallery4Title}</div>
                    <div>{data?.gallery4Subtitle}</div>
                  </div>
                </a>
              </div>
            </div>
            {/* THIS ONLY SHOWS IN DESKTOP */}
          </div>
        </div>
      </div>
    );
  }
}
