import React from "react";
import { Link } from "react-router-dom";
import { LangContext } from "../.././../languageContext";
import "./menu-open.scss";
import logo from "../../../assets/svg/logo.svg";

class MenuOpen extends React.Component {
  static contextType = LangContext;

  constructor(props) {
    super(props);
    this.state = {
      selectedMenuItem: null,
      language: "en",
      val: null,
      titles: null,
    };
  }

  handleClose () {
    this.props.close();
    setTimeout(() => {
      this.setState({ selectedMenuItem: null });
    }, 200);
    
  }

  changeLanguage(val) {
    this.context.changeLanguage(val).then(() => {
      this.setState({
        language: this.context.language,
      });
    });
  }

  componentDidUpdate() {
    if (this.context.language !== this.state.language) {
      this.setState({
        language: this.context.language,
      });
    }
    
  }
  
  componentDidMount() {
    this.getContactData();
    this.getMenuTitles();
  }

  async getContactData() {
    try {
      const response = await fetch(
        "https://n1.noxtton.com/motif-contact-and-hero-block",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-api-key": "e17fd67932e135afbe858ad03882d826feaf0124e972d08d",
          },
        }
      );
      const json = await response.json();
      this.setState({ val: json });
    } catch (err) {
      console.log(err);
    }
  }

  async getMenuTitles() {
    try {
      const response = await fetch("https://n1.noxtton.com/motif-menu-items", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-api-key": "e17fd67932e135afbe858ad03882d826feaf0124e972d08d",
        },
      });
      const json = await response.json();
      this.setState({ titles: json });
    } catch (err) {
      console.log(err);
    }
  }

  changeTab = (name) => {
    if (this.state.selectedMenuItem !== name) {
      this.setState({ selectedMenuItem: name });
    } else {
      this.setState({ selectedMenuItem: null });
    }
  };

  render() {
    const val = this.state.val
      ? this.state.val[0][this.context.language]
      : null;
    const titles = this.state.titles
      ? this.state.titles[0][this.context.language]
      : null;

    return (
      <LangContext.Consumer>
        {() => (
          <div
            className={
              "slider " +
              (this.props.active ? "menu-open" : "") +
              (this.props.closeAnimation ? "menu-close-animation" : "")
            }
          >
            <div className="slider__contents">
              <div
                className="slider__close-btn magnetic special-magnetic"
                onClick={() => this.handleClose()}
              >
                <span className="lnr lnr-cross"></span>
              </div>
              <div className="slider__central">
                <div className="slider__main">
                  {/* title1 about*/}

                  <div
                    className={
                      "slider__item " +
                      (this.state.selectedMenuItem === "about"
                        ? "slider__item-touched"
                        : "")
                    }
                  >
                    <div
                      className="slider__item-name"
                      onClick={this.props.close}
                      onTouchStart={() => this.changeTab("about")}
                    >
                      <Link className="nav-link inner-link" to="/aboutus">
                        {titles?.title1}
                      </Link>
                    </div>
                  </div>


                  {/* title4 products */}
                  <div
                    className={
                      "slider__item " +
                      (this.state.selectedMenuItem === "products"
                        ? "slider__item-touched"
                        : "")
                    }
                  >
                    <div
                      className="slider__item-name cursor-plus"
                      onTouchStart={() => this.changeTab("products")}
                    >
                      {titles?.title4}
                    </div>

                    <div className="slider__subs-container hidden">
                      <div
                        className="slider__item-sub nav-link"
                        onClick={this.props.close}
                      >
                        <Link
                          className="nav-link inner-link"
                          to="/products/bathroom"
                        >
                          {titles?.title4subtitle1}
                        </Link>
                      </div>

                      <div
                        className="slider__item-sub nav-link"
                        onClick={this.props.close}
                      >
                        <Link
                          className="nav-link inner-link"
                          to="/products/lighting"
                        >
                          {titles?.title4subtitle2}
                        </Link>
                      </div>

                      <div
                        className="slider__item-sub nav-link"
                        onClick={this.props.close}
                      >
                        <Link
                          className="nav-link inner-link"
                          to="/products/furniture"
                        >
                          {titles?.title4subtitle3}
                        </Link>
                      </div>

                      <div
                        className="slider__item-sub nav-link"
                        onClick={this.props.close}
                      >
                        <Link
                          className="nav-link inner-link"
                          to="/products/tvaudio"
                        >
                          {titles?.title4subtitle4}
                        </Link>
                      </div>

                      <div
                        className="slider__item-sub nav-link"
                        onClick={this.props.close}
                      >
                        <Link
                          className="nav-link inner-link"
                          to="/products/accessories"
                        >
                          {titles?.title4subtitle5}
                        </Link>
                      </div>

                      <div
                        className="slider__item-sub nav-link"
                        onClick={this.props.close}
                      >
                        <Link
                          className="nav-link inner-link"
                          to="/products/gifts"
                        >
                          {titles?.title4subtitle6}
                        </Link>
                      </div>

                      <div
                        className="slider__item-sub nav-link"
                        onClick={this.props.close}
                      >
                        <Link
                          className="nav-link inner-link"
                          to="/products/all"
                        >
                          {titles?.title4subtitle7}
                        </Link>
                      </div>
                    </div>
                  </div>

                  {/* title3 projects */}

                  <div
                    className={
                      "slider__item " +
                      (this.state.selectedMenuItem === "projects"
                        ? "slider__item-touched"
                        : "")
                    }
                  >
                    <div
                      className="slider__item-name"
                      onClick={this.props.close}
                      onTouchStart={() => this.changeTab("projects")}
                    >
                      <Link className="nav-link inner-link" to="/projects">
                        {titles?.title3}
                      </Link>
                    </div>
                  </div>

                  {/* title2 inspiration */}
                  <div
                    className={
                      "slider__item " +
                      (this.state.selectedMenuItem === "inspo"
                        ? "slider__item-touched"
                        : "")
                    }
                  >
                    <div
                      className="slider__item-name cursor-plus"
                      onTouchStart={() => this.changeTab("inspo")}
                    >
                      {titles?.title2}
                    </div>

                    <div className="slider__subs-container hidden">
                      <div
                        className="slider__item-sub nav-link"
                        onClick={this.props.close}
                      >
                        <Link
                          className="nav-link inner-link"
                          to="/interiors/living"
                        >
                          {titles?.title2subtitle1}
                        </Link>
                      </div>

                      <div
                        className="slider__item-sub nav-link"
                        onClick={this.props.close}
                      >
                        <Link
                          className="nav-link inner-link"
                          to="/interiors/dining"
                        >
                          {titles?.title2subtitle2}
                        </Link>
                      </div>

                      <div
                        className="slider__item-sub nav-link"
                        onClick={this.props.close}
                      >
                        <Link
                          className="nav-link inner-link"
                          to="/interiors/sleeping"
                        >
                          {titles?.title2subtitle3}
                        </Link>
                      </div>

                      <div
                        className="slider__item-sub nav-link"
                        onClick={this.props.close}
                      >
                        <Link
                          className="nav-link inner-link"
                          to="/interiors/working"
                        >
                          {titles?.title2subtitle4}
                        </Link>
                      </div>

                      <div
                        className="slider__item-sub nav-link"
                        onClick={this.props.close}
                      >
                        <Link
                          className="nav-link inner-link"
                          to="/interiors/outdoor"
                        >
                          {titles?.title2subtitle5}
                        </Link>
                      </div>

                      <div
                        className="slider__item-sub nav-link"
                        onClick={this.props.close}
                      >
                        <Link
                          className="nav-link inner-link"
                          to="/interiors/bathroom"
                        >
                          {titles?.title2subtitle6}
                        </Link>
                      </div>

                      <div
                        className="slider__item-sub nav-link"
                        onClick={this.props.close}
                      >
                        <Link
                          className="nav-link inner-link"
                          to="/interiors/homespa"
                        >
                          {titles?.title2subtitle7}
                        </Link>
                      </div>

                      <div
                        className="slider__item-sub nav-link"
                        onClick={this.props.close}
                      >
                        <Link
                          className="nav-link inner-link"
                          to="/interiors/lighting"
                        >
                          {titles?.title2subtitle8}
                        </Link>
                      </div>
                    </div>
                  </div>

                  {/* title5 services */}
                  <div
                    className={
                      "slider__item " +
                      (this.state.selectedMenuItem === "services"
                        ? "slider__item-touched"
                        : "")
                    }
                  >
                    <div
                      className="slider__item-name cursor-plus"
                      onTouchStart={() => this.changeTab("services")}
                    >
                      {titles?.title5}
                    </div>

                    <div className="slider__subs-container hidden">
                      <div
                        className="slider__item-sub nav-link"
                        onClick={this.props.close}
                      >
                        <Link
                          className="nav-link inner-link"
                          to="/installation"
                        >
                          {titles?.title5subtitle1}
                        </Link>
                      </div>

                      <div
                        className="slider__item-sub nav-link"
                        onClick={this.props.close}
                      >
                        <Link className="nav-link inner-link" to="/design">
                          {titles?.title5subtitle2}
                        </Link>
                      </div>
                    </div>
                  </div>

                  {/* title6 contact */}
                  <div
                    className={
                      "slider__item " +
                      (this.state.selectedMenuItem === "contact"
                        ? "slider__item-touched"
                        : "")
                    }
                    onTouchStart={() => this.changeTab("contact")}
                  >
                    <div
                      className="slider__item-name"
                      onClick={this.props.close}
                    >
                      <Link className="nav-link inner-link" to="/contact">
                        {titles?.title6}
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="contact-info">
                  <div
                    className="logoMenu"
                    style={{ backgroundImage: `url(${logo})` }}
                  ></div>

                  <div className="contactMenu">
                    <div className="contact__address">
                      <div>{val?.address1line1}</div>
                      <div>{val?.address1line2}</div>
                    </div>
                    <div className="contact__small-underline contact__small-underline-menu"></div>
                    <div className="contact__phone">
                      <div>{this.state.val?.[0].en.address1phonenumber}</div>
                      <div>{this.state.val?.[0].en.address1email}</div>
                    </div>

                    <div className="contact">
                      <div className="contact__address">
                        <div>{val?.address2line1}</div>
                        <div>{val?.address2line2}</div>
                      </div>
                      <div className="contact__small-underline contact__small-underline-menu"></div>
                      <div className="contact__phone">
                        <div>{this.state.val?.[0].en.address2phonenumber}</div>
                        <div>{this.state.val?.[0].en.address2email}</div>
                      </div>
                    </div>

                    <div className="contact">
                      <div className="contact__address">
                        <div>{val?.address3line1}</div>
                        <div>{val?.address3line2}</div>
                      </div>
                      <div className="contact__small-underline contact__small-underline-menu"></div>
                      <div className="contact__phone">
                        <div>{this.state.val?.[0].en.address3phonenumber}</div>
                        <div>{this.state.val?.[0].en.address3email}</div>
                      </div>
                    </div>
                  </div>

                  <div className="lang">
                    <div
                      className={
                        "ka " +
                        (this.state.language === "ka" ? "highlighted" : "")
                      }
                      onClick={() => this.changeLanguage("ka")}
                    >
                      ge
                    </div>
                    <div
                      className={
                        "en " +
                        (this.state.language === "en" ? "highlighted" : "")
                      }
                      onClick={() => this.changeLanguage("en")}
                    >
                      en
                    </div>
                  </div>
                </div>
              </div>{" "}
              {/* end of slider__central */}
            </div>
          </div>
        )}
      </LangContext.Consumer>
    );
  }
}

export default MenuOpen;
