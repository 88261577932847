import React, { Component } from "react";


export const LangContext = React.createContext();

class LanguageProvider extends Component {
  state = {
    language: localStorage.getItem("lang") || "en",
  };

  changeLanguage = (val) => {
    localStorage.setItem('lang', val);

    

    return new Promise((resolve) => {
      this.setState({ language: val }, () => {
        resolve("success");
      });
    });
  };

  render() {
    return (
      <LangContext.Provider
        value={{ language: this.state.language, changeLanguage: this.changeLanguage }}
      >
        {this.props.children}
      </LangContext.Provider>
    );
  }
}

export { LanguageProvider };
