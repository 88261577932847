import React, { useEffect, useState } from "react";
import Gallery from "react-grid-gallery";
import "./project-detail.scss";

export default function GalleryAndLightbox({ images }) {
  const [slides, setSlides] = useState([]);
  const [windowWidth, setWindowWidth] = useState(0);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setSlides([
      {
        id: 1,
        thumbnailWidth: 470,
        thumbnailHeight: 305,
        src: images[0][0] || "",
        thumbnail: images[0][1]?.length > 0 ? images[0][1] : images[0][0],
      },
      {
        id: 2,
        thumbnailWidth: 250,
        thumbnailHeight: 305,
        src: images[1][0] || "",
        thumbnail: images[1][1]?.length > 0 ? images[1][1] : images[1][0],
      },
      {
        id: 3,
        thumbnailWidth: 280,
        thumbnailHeight: 305,
        src: images[2][0] || "",
        thumbnail: images[2][1]?.length > 0 ? images[2][1] : images[2][0],
      },
      {
        id: 4,
        thumbnailWidth: 280,
        thumbnailHeight: 305,
        src: images[3][0] || "",
        thumbnail: images[3][1]?.length > 0 ? images[3][1] : images[3][0],
      },
      {
        id: 5,
        thumbnailWidth: 250,
        thumbnailHeight: 305,
        src: images[4][0] || "",
        thumbnail: images[4][1]?.length > 0 ? images[4][1] : images[4][0],
      },
      {
        id: 6,
        thumbnailWidth: 470,
        thumbnailHeight: 305,
        src: images[5][0] || "",
        thumbnail: images[5][1]?.length > 0 ? images[5][1] : images[5][0],
      },
    ]);
  }, [images]);

  return (
    <Gallery
      images={slides}
      rowHeight={305}
      backdropClosesModal={true}
      showImageCount={false}
      enableImageSelection={false}
      showCloseButton={windowWidth < 1024 ? true : false}
    />
  );
}
