import React from "react";
// import {ReactComponent as NextButton} from '../../assets/components/cursor-next.svg';
import { LangContext } from "../../languageContext";
import "./projects.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import { ReactComponent as Outline } from "../../assets/components/outline.svg";

export default class Projects extends React.Component {
  static contextType = LangContext;

  settings = {
    infinite: false,
    easing: "ease-out",
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    arrows: false,
    afterChange: (index) => {
      this.setState({ currentSlide: index });
    },
  };

  state = {
    currentSlide: 0,
    data: null,
    mainData: null,
  };

  componentDidMount() {
    this.getProjectsData();
    this.getMainData();
  }

  async getProjectsData() {
    try {
      const response = await fetch("https://n1.noxtton.com/motif-project", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-api-key": "e17fd67932e135afbe858ad03882d826feaf0124e972d08d",
        },
      });
      const json = await response.json();
      this.setState({ data: json });
      this.createSlugs();
    } catch (err) {
      console.log(err);
    }
  }

  async getMainData() {
    try {
      const response = await fetch(
        "https://n1.noxtton.com/motif-project-main-page",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-api-key": "e17fd67932e135afbe858ad03882d826feaf0124e972d08d",
          },
        }
      );
      const json = await response.json();
      this.setState({ mainData: json[0] });
    } catch (err) {
      console.log(err);
    }
  }

  createSlugs = () => {
    if (this.state.data) {
      let val = this.state.data;
      val = val.map((item) => {
        let slug = item.en.title.toLowerCase().replace(/ /g, "-");
        return { ...item, slug: slug };
      });
      this.setState({
        data: val,
      });
    }
  };

  createMarkup = (val) => {
    return { __html: val };
  };

  render() {
    const val = this.state.data;
    const mainVal = this.state.mainData
      ? this.state.mainData[this.context.language]
      : null;

    return (
      <div className="projects">
        <div className="projects__inner">
          <div className="projects__text">
            <div className="site-path projects__site-path">
              <span>
                <a href="/">
                  {this.context.language === "en" ? "Home" : "მთავარი გვერდი"}
                </a>{" "}
                / {this.context.language === "en" ? "Projects" : "პროექტები"}
              </span>
            </div>
            <div className="projects__name">{mainVal?.title}</div>
            <div
              className="projects__story"
              dangerouslySetInnerHTML={this.createMarkup(mainVal?.text)}
            ></div>
          </div>

          {val ? (
            <div className="projects__gallery">
              <div className="projects__inner-gallery">
                <Slider
                  ref={(slider) => (this.slider = slider)}
                  {...this.settings}
                >
                  {val.map((item) => (
                    <React.Fragment key={item?.id}>
                      <div
                        className="projects__gallery-item"
                        style={{
                          backgroundImage: `url('${item?.en.sliderImgSm}')`,
                        }}
                        onClick={() => this.slider.slickNext()}
                      >
                        <div className="projects__overlay"></div>
                        <div className="projects__overlay-text">
                          <Link
                            to={`/projects/${item?.slug}`}
                            className="inner-link"
                          >
                            <div className="projects__overlay-text-item">
                              {this.context.language === "en"
                                ? "Interior"
                                : "ინტერიერი"}{" "}
                              {item?.en.exterior === "true" ? (
                                <>
                                  <span></span>{" "}
                                  {this.context.language === "en"
                                    ? "Exterior"
                                    : "ექსტერიერი"}
                                </>
                              ) : null}
                            </div>
                            <div className="projects__overlay-text-item">
                              {item?.en.title}
                            </div>
                          </Link>
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
                </Slider>
                <div className="projects__slides-num">
                  <span>
                    {this.state.currentSlide + 1} / {val.length}
                  </span>
                </div>

                <div className="slider-prev-next-buttons">
                  <div
                    className="slider-prev-button"
                    style={{
                      visibility:
                        this.state.currentSlide > 0 &&
                        this.state.currentSlide < val.length
                          ? "visible"
                          : "hidden",
                    }}
                    onClick={() => {
                      this.slider.slickPrev();
                    }}
                  >
                    <div className="magnetic arrow-magnetic-button special-magnetic">
                      <Outline />
                      <span className="lnr lnr-arrow-right pointers-none"></span>
                    </div>
                  </div>
                  <div
                    className="slider-next-button"
                    style={{
                      visibility:
                        this.state.currentSlide + 1 < val.length
                          ? "visible"
                          : "hidden",
                    }}
                    onClick={() => {
                      this.slider.slickNext();
                    }}
                  >
                    <div className="magnetic arrow-magnetic-button special-magnetic">
                      <Outline />
                      <span className="lnr lnr-arrow-right pointers-none"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
