import React from "react";
import { LangContext } from "../../../languageContext";

import "./gallery.scss";
import { ReactComponent as Line } from "./line.svg";
import { ReactComponent as Line2 } from "./line2.svg";

import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";


const responsive = {
  1450: { items: 5 },
  1150: { items: 4 },
  900: { items: 3 },
  625: { items: 2 },
  300: { items: 1 },
};

class Gallery extends React.Component {
  static contextType = LangContext;

  constructor(props) {
    super(props);
    this.slider = React.createRef(null);

    this.state = {
      data: null,
      slides: []
    };
  }

  componentDidMount = () => {
    this.loadData();
  };

  async loadData() {
    try {
      const response = await fetch(
        "https://n1.noxtton.com/motif-home-products",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-api-key": "e17fd67932e135afbe858ad03882d826feaf0124e972d08d",
          },
        }
      );
      const json = await response.json();

      this.createSlides(json);
    } catch (err) {
      console.log(err);
    }
  }

  createSlides = sectionData => {
    const tempArr = [];
    sectionData.forEach((i, index) => {
      let obj = {
        source: i.en.image,
        id: index,
        title: i.en.title,
        enLink: i.en.link,
      };
      if (i.ka && i.ka.link) {
        obj = { ...obj, kaLink: i.ka.link };
      }
      tempArr.push(obj);
    });
    this.setState({slides: tempArr});
  };

  next = (e) => {
    const width = window.innerWidth;
    if (width >= 1024 && e.clientX < width / 2) {
      this.slider.current.slidePrev();
    } else {
      this.slider.current.slideNext();
    }
  };

  getItems = () => {
      return this.state.slides.map((item) => (
        <div className="slider-item-wrapper" style={{ width: 230 }} key={item.id}>
          <div
            onClick={(e) => this.next(e)}
            className="alice-carousel-item gallery-cursor"
            style={{ backgroundImage: `url('${item?.source}')` }}
          ></div>
          <div className="text">
            <div>{item?.title}</div>
            <div className="outside-link">
              <a
                target="_blank"
                rel="noreferrer"
                href={
                  this.context.language === "ka" && item?.kaLink
                    ? item?.kaLink
                    : item?.enLink
                }
              >
                {this.context.language === "en" ? "Visit web" : "ეწვიეთ გვერდს"}
                <span>
                  <i className="lnr lnr-arrow-right"></i>
                </span>
              </a>
            </div>
          </div>
          <div className="slider-item-overlay">
            <Line />
            <Line2 />
          </div>
        </div>
      ));
  }

  render() {

    return (
      <div className="gallery">
       {this.state.slides.length > 0 ?
        <AliceCarousel
          touchMoveDefaultEvents={true}
          mouseTracking
          infinite
          disableDotsControls
          disableButtonsControls
          paddingLeft={30}
          paddingRight={50}
          responsive={responsive}
          items={this.getItems()}
          ref={this.slider}
        />
      : null}
      </div>
    );
  }
}

export default Gallery;
