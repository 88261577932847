import React from "react";
import { LangContext } from "../../../languageContext";
import { Link } from "react-router-dom";
import "./overlay-menu.scss";
import burger from "../../../assets/components/burger_menu_white.png";
import logo from "../../../assets/components/Motif_logo_white.svg";
import MenuOpen from "../menu-open/menu-open";
import classnames from "classnames";

class Menu extends React.Component {
  static contextType = LangContext;

  burgerLogo;
  logo;
  textColor;
  shopFontWeight;

  constructor(props) {
    super(props);

    this.state = {
      language: "en",
      active: false,
      playCloseAnimation: false,
      visible: true,
      prevScrollpos: window.pageYOffset,
    };
    this.hideBar = this.hideBar.bind(this);
    this.burgerLogo = this.props.burgerLogo || burger;
    this.logo = this.props.logo || logo;
    this.textColor = this.props.textColor || "black";
    this.shopFontWeight = this.props.shopFontWeight || "normal";
  }

  hideBar() {
    this.setState({
      visible: window.pageYOffset <= 350  });
  }

  openMenu() {
    this.setState({
      active: true,
    });
  }

  removePlayAnimation = () => {
    this.setState({
      playCloseAnimation: false,
    });
  };

  closeMenu = () => {
    this.setState({
      active: false,
      playCloseAnimation: true,
    });
    setTimeout(() => {
      this.removePlayAnimation();
    }, 500);
  };

  componentDidMount() {
    window.addEventListener("scroll", this.hideBar, false);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.hideBar, false);
  }

  render() {
    return (
      <>
        <MenuOpen
          active={this.state.active}
          close={this.closeMenu}
          closeAnimation={
            this.state.playCloseAnimation ? "menu-close-animation" : ""
          }
        />

        <div
          className={classnames("container-fluid overlay-topbar", {
            "overlay-topbar-hidden": !this.state.visible,
          })}
        >
          <div
            className="burger-container magnetic special-magnetic"
            onClick={() => this.openMenu()}
          >
            <div
              className="overlay-topbar__burger"
              style={{ backgroundImage: `url('${this.burgerLogo}')` }}
            ></div>
          </div>

          <div className="overlay-topbar__logo magnetic">
            <Link to="/">
              <img
                className="logo-image-hover"
                src={this.logo}
                alt="motif logo"
              />
            </Link>
          </div>

          <div className="overlay-topbar__shop burger-container">
            <a
              target="_blank"
              rel="noreferrer"
              href={
                this.context.language === "en"
                  ? "https://shop.motif.ge/"
                  : "https://shop.motif.ge/"
              }
            >
              Shop
            </a>
          </div>
        </div>
      </>
    );
  }
}

export default Menu;
