import React from "react";
import { LangContext } from "../../languageContext";
import { Link } from "react-router-dom";
// import {ReactComponent as NextButton} from '../../assets/components/cursor-next.svg';
import "./aboutus.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ReactComponent as Outline } from "../../assets/components/outline.svg";

const slides = [
  {
    id: 1,
  },
  {
    id: 2,
  },
  {
    id: 3,
  },
];

export default class AboutUs extends React.Component {
  static contextType = LangContext;

  settings = {
    infinite: false,
    slidesToShow: 1,
    swipeToSlide: true,
    arrows: false,
    variableWidth: true,
    afterChange: (index) => {
      this.setState({ currentSlide: index });
    },
  };

  state = {
    currentSlide: 0,
    data: null,
    imagesAdded: false,
  };

  componentDidMount = () => {
    this.getData();
  };

  async getData() {
    try {
      const response = await fetch("https://n1.noxtton.com/motif-about-us", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-api-key": "e17fd67932e135afbe858ad03882d826feaf0124e972d08d",
        },
      });
      const json = await response.json();
      this.setState({ data: json }, this.createSlides);
    } catch (err) {
      console.log(err);
    }
  }

  createSlides = () => {
    if (this.state.data) {
      let data = this.state.data[0].en;
      slides[0].src = data?.gallery1Full;
      slides[1].src = data?.gallery2Full;
      slides[2].src = data?.gallery3Full;
    }
    this.setState({ imagesAdded: true });
  };

  //   We believe in the true beauty of authenticity. Timeless yet contemporary, contrast yet softness, where less becomes more. Committed to creativity that takes courage, authentic materials and quality craftsmanship that simply speaks for itself and lasts over time.

  // We do architecture and interior concepts for both property developments, private homes and commercial spaces for people to find home and comfort. Each assignment is tailored to meet the needs and desires of the clients.

  createMarkup = (val) => {
    return { __html: val };
  };

  render() {
    let data = this.state.data
      ? this.state.data[0][this.context.language]
      : null;

    return (
      <div className="aboutus" id="aboutus">
        <div className="aboutus__text">
          <div className="site-path aboutus__site-path">
            <span>
              <Link to="/">
                {this.context.language === "en" ? "Home" : "მთავარი გვერდი"}
              </Link>{" "}
              / {this.context.language === "en" ? "About us" : "ჩვენ შესახებ"}
            </span>
          </div>

          <div className="aboutus__name">
            <span className="aboutus__back-btn">
              <Link to="/" className="aboutus__back magnetic">
                <div className="arrow-magnetic-button">
                  <Outline />
                  <span className="lnr lnr-arrow-right"></span>
                </div>
              </Link>
            </span>
            <span>{data?.title}</span>
          </div>
          <div
            className="aboutus__story"
            dangerouslySetInnerHTML={this.createMarkup(data?.text)}
          ></div>
        </div>

        <div className="aboutus__gallery">
          <div className="aboutus__inner-gallery">
            {this.state.imagesAdded ? (
              <>
                <Slider
                  ref={(slider) => (this.slider = slider)}
                  {...this.settings}
                >
                  {slides?.map((item) => (
                    <React.Fragment key={item?.id}>
                      <div
                        className="aboutus__gallery-item"
                        style={{
                          backgroundImage: `url('${item?.src && item.src}')`,
                        }}
                      ></div>
                    </React.Fragment>
                  ))}
                </Slider>
                <div className="aboutus__slides-num">
                  <span>
                    {this.state.currentSlide + 1} / {slides.length}
                  </span>
                </div>

                <div className="slider-prev-next-buttons">
                  <div
                    className="slider-prev-button"
                    style={{
                      visibility:
                        this.state.currentSlide > 0 &&
                        this.state.currentSlide < slides.length
                          ? "visible"
                          : "hidden",
                    }}
                    onClick={() => {
                      this.slider.slickPrev();
                    }}
                  >
                    <div className="magnetic arrow-magnetic-button special-magnetic">
                      <Outline />
                      <span className="lnr lnr-arrow-right"></span>
                    </div>
                  </div>
                  <div
                    className="slider-next-button"
                    style={{
                      visibility:
                        this.state.currentSlide + 1 < slides.length
                          ? "visible"
                          : "hidden",
                    }}
                    onClick={() => {
                      this.slider.slickNext();
                    }}
                  >
                    <div className="magnetic arrow-magnetic-button special-magnetic">
                      <Outline />
                      <span className="lnr lnr-arrow-right"></span>
                    </div>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
