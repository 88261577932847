import React from "react";
import "./banner.scss";

class Banner extends React.Component {

  banner;

  constructor(props) {
    super(props);
    this.state = {
      bannerOffset: 0
    };
    this.scroll = this.scroll.bind(this);
    this.banner = React.createRef();
  }

  getBannerOffset() {
    return window.pageYOffset - this.banner.current.offsetTop + window.innerHeight;
  }

  scroll() {
    this.setState({ bannerOffset: this.getBannerOffset() });
  }

  componentDidMount() {
    this.setState({ bannerOffset: this.getBannerOffset() });
    window.addEventListener("scroll", this.scroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scroll);
  }

  render() {
    return (
      <div className="box">
        <section
          ref={this.banner}
          style={{ transform: `translateX(-${this.state.bannerOffset}px)` }}
          className="banner">
          <div className="banner--content">
          MOTIF DESIGN ELEMENTS MOTIF DESIGN ELEMENTS MOTIF DESIGN ELEMENTS MOTIF DESIGN ELEMENTS MOTIF DESIGN ELEMENTS MOTIF DESIGN ELEMENTS MOTIF DESIGN ELEMENTS
          </div>
        </section>
      </div>
    );
  }
}

export default Banner;
