import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ScrollToTop from 'react-router-scroll-top';
import "./App.css";
import Menu from "./components/menu/menu";
import Footer from "./components/footer/footer";
import Home from "./components/home/home";
import Products from "./components/products/products";
import Installation from "./components/installation/installation";
import Architecture from "./components/architecture/architecture";
import AboutUs from "./components/aboutus/aboutus";
import Contact from "./components/contact/contact";
import Projects from "./components/projects/projects";
import ProjectDetail from "./components/projects/project-detail/project-detail";
import Interiors from "./components/interiors/interiors";
import InteriorsDetail from "./components/interiors/interiors-detail/interiors-detail";
import { Helmet } from "react-helmet";
import Cursor from "./components/cursor/cursor";

function App() {
  


  return (
    
    <Router>
    <ScrollToTop>
      <div className="App" id="app-root">
        <Helmet>
          <title>MOTIF</title>
        </Helmet>

        <Cursor/>

        <Menu/>

        <Switch>
          <Route path="/projects/:slug" component={ProjectDetail} />

          <Route path="/interiors/:slug" component={InteriorsDetail} />

          <Route path="/contact">
            <Contact />
          </Route>

          <Route path="/projects">
            <Projects />
          </Route>

          <Route path="/interiors">
            <Interiors />
          </Route>

          <Route path="/products/:slug" component={Products} />

          <Route path="/installation">
            <Installation />
          </Route>

          <Route path="/design">
            <Architecture />
          </Route>

          <Route path="/aboutus">
            <AboutUs />
          </Route>


          <Route path="">
            <Home />
          </Route>
        </Switch>
        <Footer />
      </div>
      </ScrollToTop>
    </Router>
    
  );
}

export default App;
