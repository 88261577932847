import React from "react";
import { LangContext } from "../../../languageContext";
import "./filter.scss";
import "../checkbox.scss";
import productsJson from "../../../json/products.json";
import OutsideClickHandler from "react-outside-click-handler";
import config from "../../../env";

export default class Filter extends React.Component {
  static contextType = LangContext;

  constructor(props) {
    super(props);
    this.state = {
      typeOpen: false,
      designerOpen: false,
      brandOpen: false,
      isDesktop: false,
      selectedCategories: [],
      selectedDesigners: [],
      selectedBrands: [],
      anyChecked: false,
      //filling filter
      categoriesData: null,
      searchResultsArr: [],
      brands: [],
      designers: [],
    };
  }

  removeSpacesFromString(string) {
    if (string !== undefined) {
      return string.replace(/\s/g, "");
    }
  }

  getData() {
    return productsJson[this.context.language];
    // Note: product.id === product['id']
  }

  createProductsList() {
    let arr = [];
    for (let i = 0; i < 63; i++) {
      arr.push({
        key: i,
        name: "Product",
        designer: "Designer",
        brand: "Brand",
        picture: "yellowSofa",
      });
    }
    return arr;
  }

  componentDidMount() {
    this.getCategoriesJson();
    this.updateScreenWidth();
    window.addEventListener("resize", this.updateScreenWidth);

    this.createBrands();
    this.createDesigners();
  }

  //CLEAR FILTER ON SLUG CHANGE
  componentDidUpdate(prevProps) {
    if (prevProps.slug !== this.props.slug) {
      this.handleClearAll();
    }
    if (prevProps.brandsData !== this.props.brandsData) {
      this.createBrands();
    }
    if (prevProps.designersData !== this.props.designersData) {
      this.createDesigners();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateScreenWidth);
  }

  updateScreenWidth = () => {
    if (window.innerWidth > 1024) {
      this.setState({ isDesktop: true });
    } else {
      this.setState({ isDesktop: false });
    }
  };

  //filter categories according to slug
  async getCategoriesJson() {
    try {
      const response = await fetch(
        `https://n1.noxtton.com/motif-filter-categories-json`,
        config
      );
      const json = await response.json();
      if (json) {
        this.setState({ categoriesJson: json }, this.loadCategoriesData);
      }
    } catch (err) {
      console.log(err);
    }
  }

  //fill filter with data

  async loadCategoriesData() {
    try {
      const response = await fetch(
        `https://n1.noxtton.com/motif-filter-categories`,
        config
      );
      const json = await response.json();
      if (json) {
        this.setState({ categoriesData: json }, this.createCategories);
      }
    } catch (err) {
      console.log(err);
    }
  }

  // CREATING CATEGORIES

  //THE CATEGORIES NEED TO BE DIFFERENT ON DIFFERENT PRODUCT PAGES
  //WE GET THEM FROM CMS

  reduceFilterCategories = (categoriesJson) => {
    const getKeys = (data) => {
      const currentFilterCategories = [];

      for (const [key, value] of Object.entries(data)) {
        if (
          key.replace(/[^A-Z\d\s]/gi, "").toLowerCase() ===
          this.props.slug.toLowerCase()
        ) {
          currentFilterCategories.push(value);
        }
      }
      return currentFilterCategories[0];
    };

    return getKeys(JSON.parse(categoriesJson[0].en.json));
  };

  createCategories = () => {
    let data = null;

    if (this.state.categoriesJson && this.props.slug !== "all") {
      const categoriesArray = this.reduceFilterCategories(this.state.categoriesJson);

      const arr = [];
      (categoriesArray || []).forEach((item) => {
        return this.state.categoriesData?.forEach((j) => {
          if (
            j.en && j.en.categoryname.replace(/[^A-Z\d\s]/gi, "").toLowerCase() ===
            item.replace(/[^A-Z\d\s]/gi, "").toLowerCase()
          ) {
            arr.push(j);
          }
        });
      });
      data = arr;
    } else {
      data = this.state.categoriesData;
    }

    function compare(a, b) {
      if (a.en.categoryname < b.en.categoryname) {
        return -1;
      }
      if (a.en.categoryname > b.en.categoryname) {
        return 1;
      }
      return 0;
    }
    let objectsArr = [];

    if (data) {
      objectsArr = data.sort(compare);
      if (objectsArr.length % 3 === 2) {
        objectsArr.push(" ");
      }
    }
    return objectsArr;
  };

  // END OF CREATING CATEGORIES

  createBrands = () => {
    if (this.props.brandsData) {
      const data = [...this.props.brandsData];

      const brandsArr = data.map((item) => item?.en.name.trim());

      let temp = brandsArr.filter((v, i, a) => a.indexOf(v) === i); //removing duplicate items
      if (temp.length % 3 === 2) {
        temp.push(" "); //to keep layout of three items per row
      }
      this.setState({
        brands: temp,
      });
    }
  };

  createDesigners = () => {
    if (this.props.designersData) {
      const data = [...this.props.designersData];

      const designersArr = data.map((item) => item?.en.name.trim());

      let temp = designersArr.filter((v, i, a) => a.indexOf(v) === i); //removing duplicate items
      if (temp.length % 3 === 2) {
        temp.push(" "); //to keep layout of three items per row
      }
      this.setState({
        designers: temp,
      });
    }
  };

  removeDuplicateValues = (val) => {
    let unique = {};
    val.forEach(function (i) {
      if (!unique[Object.values(i)]) {
        unique[Object.values(i)] = true;
      }
    });
    return Object.keys(unique);
  };

  //FOR WHEN INPUTS ARE CHECKED ->

  handleChangeProducts = (e) => {
    let val = this.state.categoriesData.find((i) => i.id === e.target.value);
    if (e.target.checked) {
      this.setState({
        selectedCategories: [val, ...this.state.selectedCategories],
      });
    } else {
      const index = this.state.selectedCategories.indexOf(e.target.value);
      this.state.selectedCategories.splice(index, 1);
      this.setState({
        selectedCategories: this.state.selectedCategories,
      });
    }
    if (document.querySelectorAll(".checkbox-inputs:checked").length) {
      this.setState({ anyChecked: true });
    }
  };

  handleChangeDesigners = (e) => {
    if (e.target.checked) {
      this.setState({
        selectedDesigners: [e.target.value, ...this.state.selectedDesigners],
      });
    } else {
      const index = this.state.selectedDesigners.indexOf(e.target.value);
      this.state.selectedDesigners.splice(index, 1);
      this.setState({
        selectedDesigners: this.state.selectedDesigners,
      });
    }
    if (document.querySelectorAll(".checkbox-inputs:checked").length) {
      this.setState({ anyChecked: true });
    }
  };

  handleChangeBrands = (e) => {
    if (e.target.checked) {
      this.setState({
        selectedBrands: [e.target.value, ...this.state.selectedBrands],
      });
    } else {
      const index = this.state.selectedBrands.indexOf(e.target.value);
      this.state.selectedBrands.splice(index, 1);
      this.setState({
        selectedBrands: this.state.selectedBrands,
      });
    }
    if (document.querySelectorAll(".checkbox-inputs:checked").length) {
      this.setState({ anyChecked: true });
    }
  };

  //TO CLEAR ALL CHECKBOXES

  handleClearAll = () => {
    const nodeList = document.querySelectorAll(".checkbox-inputs:checked");
    if (nodeList.length) {
      nodeList.forEach((item) => {
        item.checked = false;
      });

      this.setState({
        selectedCategories: [],
        selectedDesigners: [],
        selectedBrands: [],
        anyChecked: false,
        typeOpen: false,
        designerOpen: false,
        brandOpen: false,
      });
    }

    this.props.changeDisplayedData(this.props.data);
  };

  viewall = () => {
    // this.props.changeDisplayedData(this.props.data);
    return;
  };

  //TO ONLY HAVE ONE FILTER OPEN AT A TIME

  closeOtherFilters(filter) {
    this.setState({
      typeOpen: filter === "type" ? !this.state.typeOpen : false,
      designerOpen: filter === "designer" ? !this.state.designerOpen : false,
      brandOpen: filter === "brand" ? !this.state.brandOpen : false,
    });
  }

  //SEARCH

  searchProducts = () => {
    const c = this.state.selectedCategories;
    const d = this.state.selectedDesigners;
    const b = this.state.selectedBrands;

    let products = this.props.data;

    products.forEach((item) => {
      if (item.en.brand) {
        item.en.brand = item.en.brand.trim();
      }
      if (item.en.designer) {
        item.en.designer = item.en.designer.trim();
      }
      if (item.en.category) {
        item.en.category = item.en.category.trim();
      }
    });
    if (products) {
      if (c.length) {
        let categories = c.map((item) => {
          return String(item.en.categoryname).toLowerCase();
        });
        products = products.filter((item) => {
          return categories.includes(String(item.en.category).toLowerCase());
        });
      }

      if (d.length) {
        let tempDesigners = [];

        for (let i = 0; i < d.length; i++) {
          for (let j = 0; j < products.length; j++) {
            if (
              this.removeSpacesFromString(
                products[j].en.designer?.trim().toLowerCase()
              ) === this.removeSpacesFromString(d[i].trim().toLowerCase())
            ) {
              tempDesigners.push(products[j]);
            }
          }
        }

        products = tempDesigners;
      }

      if (b.length) {
        let tempBrands = [];

        for (let i = 0; i < b.length; i++) {
          for (let j = 0; j < products.length; j++) {
            if (
              this.removeSpacesFromString(
                products[j].en.brand?.trim().toLowerCase()
              ) === this.removeSpacesFromString(b[i].trim().toLowerCase())
            ) {
              tempBrands.push(products[j]);
            }
          }
        }

        products = tempBrands;
      }

      this.props.changeDisplayedData(products);

      this.setState({
        typeOpen: false,
        designerOpen: false,
        brandOpen: false,
      });
    }
  };

  checkIfProductWithGivenValueExists(key, value) {
    let index;

    if(this.props.data && typeof value ==  "string") {
      if (key === "brand" || key === "designer") {
      index = this.props.data.findIndex((product) => 
        this.removeSpacesFromString(product.en[key]?.toLowerCase()) === this.removeSpacesFromString(value?.toLowerCase())
      )
    }
    }
    return (index !== -1)
  }

  render() {
    let categoriesArr = this.createCategories();
    return (
      <OutsideClickHandler
        onOutsideClick={() => {
          this.closeOtherFilters("all");
        }}
      >
        <div className="products__filter">
          <form>
            <div className="products__filter-inner cursor-plus">
              <div
                className="products__upper"
                onClick={() => this.closeOtherFilters("type")}
              >
                <div className="products__text">
                  <div className="products__name">
                    {this.context.language === "en" ? "Product" : "პროდუქტი"}
                  </div>
                  <div className="products__viewall">
                    {!this.state.selectedCategories.length ? (
                      <div className="filter-viewall" onClick={this.viewall}>
                        {this.context.language === "en"
                          ? "View all"
                          : "ყველას ნახვა"}
                      </div>
                    ) : (
                      <div className="products__selected">
                        {this.state.selectedCategories
                          .map(
                            (item) => this.context.language ? item[this.context.language].categoryname : ''
                          )
                          .join(", ")}
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className={
                    "products__icons " + (this.state.typeOpen ? "focused" : "")
                  }
                >
                  <span
                    className={
                      "icon-default las " +
                      (this.state.isDesktop ? "la-angle-down" : "la-plus")
                    }
                  ></span>
                  <span
                    className={
                      "icon-focus las " +
                      (this.state.isDesktop ? "la-angle-up" : "la-minus")
                    }
                  ></span>
                </div>
              </div>
              <div
                className={
                  "prods products__filter-list " +
                  (this.state.typeOpen ? "list-open" : "")
                }
              >
                <div className="products__filter-inputwrapper">
                  {/* CATEGORIES FILTER */}
                  {categoriesArr
                    ? categoriesArr.map((item) => (
                        <div key={item.id}>
                          <span className="checkbox__input">
                            <span className="checkbox bounce">
                              <input
                                className="checkbox-inputs"
                                type="checkbox"
                                id={item?.id}
                                value={item?.id}
                                onChange={this.handleChangeProducts}
                              />

                              <svg viewBox="0 0 21 21">
                                <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
                              </svg>
                              <label
                                htmlFor={item?.id}
                                className="checkbox checkbox-cursor-hover"
                                style={{ whiteSpace: "nowrap" }}
                              >
                                {!!item && item?.[this.context.language] && item?.[this.context.language].categoryname}
                              </label>
                            </span>
                          </span>
                        </div>
                      ))
                    : null}
                </div>
              </div>
            </div>

            {/* BRANDS FILTER */}
            <div className="products__filter-inner cursor-plus">
              <div
                className="products__upper"
                onClick={() => this.closeOtherFilters("brand")}
              >
                <div className="products__text">
                  <div className="products__name">
                    {this.context.language === "en" ? "Brand" : "ბრენდი"}
                  </div>
                  <div className="products__viewall">
                    {!this.state.selectedBrands.length ? (
                      <div className="filter-viewall" onClick={this.viewall}>
                        {this.context.language === "en"
                          ? "View all"
                          : "ყველას ნახვა"}
                      </div>
                    ) : (
                      <div className="products__selected">
                        {this.state.selectedBrands.join(", ")}
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className={
                    "products__icons " + (this.state.brandOpen ? "focused" : "")
                  }
                >
                  <span
                    className={
                      "icon-default las " +
                      (this.state.isDesktop ? "la-angle-down" : "la-plus")
                    }
                  ></span>
                  <span
                    className={
                      "icon-focus las " +
                      (this.state.isDesktop ? "la-angle-up" : "la-minus")
                    }
                  ></span>
                </div>
              </div>
              <div
                className={
                  "brand products__filter-list " +
                  (this.state.brandOpen ? "list-open" : "")
                }
              >
                <div className="products__filter-inputwrapper">
                  {this.state.brands.length
                    ? this.state.brands.map((item, index) => {
                        return (
                          <div key={index}>
                            <span className="checkbox__input">
                              <span className="checkbox bounce">
                                <input
                                  className="checkbox-inputs"
                                  type="checkbox"
                                  id={`brand${index}`}
                                  value={item}
                                  onChange={this.handleChangeBrands}
                                  disabled={!this.checkIfProductWithGivenValueExists("brand", item)}
                                />

                                <svg viewBox="0 0 21 21">
                                  <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
                                </svg>
                                <label
                                  htmlFor={`brand${index}`}
                                  className="checkbox"
                                  style={{ 
                                    whiteSpace: "nowrap",
                                    color: this.checkIfProductWithGivenValueExists("brand", item) ? "inherit" : "#cfcfcf"
                                 }}
                                >
                                  {item}
                                </label>
                              </span>
                            </span>
                          </div>
                        );
                      })
                    : null}
                </div>
              </div>
            </div>

            {/* DESIGNER FILTER */}
            <div className="products__filter-inner cursor-plus">
              <div
                className="products__upper"
                onClick={() => this.closeOtherFilters("designer")}
              >
                <div className="products__text">
                  <div className="products__name">
                    {this.context.language === "en" ? "Designer" : "დიზაინერი"}
                  </div>
                  <div className="products__viewall">
                    {!this.state.selectedDesigners.length ? (
                      <div className="filter-viewall" onClick={this.viewall}>
                        {this.context.language === "en"
                          ? "View all"
                          : "ყველას ნახვა"}
                      </div>
                    ) : (
                      <div className="products__selected">
                        {this.state.selectedDesigners.join(", ")}
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className={
                    "products__icons " +
                    (this.state.designerOpen ? "focused" : "")
                  }
                >
                  <span
                    className={
                      "icon-default las " +
                      (this.state.isDesktop ? "la-angle-down" : "la-plus")
                    }
                  ></span>
                  <span
                    className={
                      "icon-focus las " +
                      (this.state.isDesktop ? "la-angle-up" : "la-minus")
                    }
                  ></span>
                </div>
              </div>
              <div
                className={
                  "designer products__filter-list " +
                  (this.state.designerOpen ? "list-open" : "")
                }
              >
                <div className="products__filter-inputwrapper">
                  {this.state.designers.length
                    ? this.state.designers.map((item, index) => {
                        return (
                          <div key={index}>
                            <span className="checkbox__input">
                              <span className="checkbox bounce">
                                <input
                                  className="checkbox-inputs"
                                  type="checkbox"
                                  id={`designer${index}`}
                                  value={item}
                                  onChange={this.handleChangeDesigners}
                                  disabled={!this.checkIfProductWithGivenValueExists("designer", item)}
                                />

                                <svg viewBox="0 0 21 21">
                                  <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
                                </svg>
                                <label
                                  htmlFor={`designer${index}`}
                                  className="checkbox"
                                  style={{ 
                                    whiteSpace: "nowrap",
                                    color: this.checkIfProductWithGivenValueExists("designer", item) ? "inherit" : "#cfcfcf"
                                  }}
                                >
                                  {item}
                                </label>
                              </span>
                            </span>
                          </div>
                        );
                      })
                    : null}
                </div>
              </div>
            </div>
          </form>
          <div className="clear-button">
            {this.state.anyChecked ? (
              <>
                <span
                  onClick={this.handleClearAll}
                  style={{ marginRight: "20px" }}
                >
                  {this.context.language === "en" ? "Clear all" : "წაშლა"}
                </span>
                <span
                  className="search-span-icon"
                  onClick={this.searchProducts}
                >
                  {this.context.language === "en" ? "Search" : "ძიება"}{" "}
                  <span className="lnr lnr-arrow-right"></span>
                </span>
              </>
            ) : null}
          </div>
        </div>
      </OutsideClickHandler>
    );
  }
}
