import React from "react";
import {Link} from "react-router-dom";
import './menu.scss';
import burger from "../../assets/svg/burger.svg";
import logo from "../../assets/svg/logo.svg";
import MenuOpen from "./menu-open/menu-open";

class Menu extends React.Component {
    constructor(props){
        super(props);
        this.state={
            isHide: false,
            language: "en",
            active: false,
            playCloseAnimation: false,
        };
        this.hideBar = this.hideBar.bind(this);
      }

      hideBar(){
        let {isHide} = this.state
        window.scrollY > this.prev?
        !isHide && this.setState({isHide:true})
        :
        isHide && this.setState({isHide:false})
        this.prev = window.scrollY;
     }

     openMenu() {
         this.setState({
             active: true,
         });
     }

     removePlayAnimation = () => {
            this.setState({
                playCloseAnimation: false
            })
     }

     closeMenu = () => {
            this.setState({
                active: false,
                playCloseAnimation: true
            });
            setTimeout(() => {
                this.removePlayAnimation()
            }, 500)
     };

     

     componentDidMount(){
         window.addEventListener('scroll',this.hideBar);
     }
     componentWillUnmount(){
          window.removeEventListener('scroll',this.hideBar);
     }



render() {
    let classHide=this.state.isHide ? "hide" : "";
    

    return (
        <>

        <MenuOpen active={this.state.active} close={this.closeMenu} closeAnimation={this.state.playCloseAnimation ? "menu-close-animation" : ""}/>
        
        <div className={"container-fluid topbar " + classHide}>
        <div className="burger-container magnetic special-magnetic" onClick={() => this.openMenu()}>
        <div className="topbar__burger" style={{ backgroundImage: `url(${burger})`}}></div>
        </div>
            

            <div className="topbar__logo magnetic">
                <Link to="/"><img className="logo-image-hover" src={logo} alt="motif logo"/></Link>
            </div>

            
            <div className="topbar__shop burger-container">
                <a 
                target="_blank"
                rel="noreferrer"
                href={this.context.language === "en" ? "https://shop.motif.ge/" : "https://shop.motif.ge/"}>Shop</a>
            </div>
            


        </div>
        </>
    )
}
}

export default Menu;