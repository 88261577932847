import React from "react";
import { LangContext } from "../../../languageContext";
import "./related-products.scss";

class RelatedProducts extends React.Component {
  static contextType = LangContext;

  constructor(props) {
    super(props);

    this.state = {
      sectionData: null,
    };
  }
  componentDidMount = () => {
    this.loadSectionData();
  };

  async loadSectionData() {
    try {
      const response = await fetch(
        "https://n1.noxtton.com/motif-home-section",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-api-key": "e17fd67932e135afbe858ad03882d826feaf0124e972d08d",
          },
        }
      );
      const json = await response.json();
      if (json) {
        this.setState({ sectionData: json });
      }
    } catch (err) {
      console.log(err);
    }
  }

  createMarkup = (val) => {
    return { __html: val };
  };

  render() {
    let sectionData =
      this.state.sectionData && this.state.sectionData[this.props.order]
        ? this.state.sectionData[this.props.order][this.context.language]
        : null;

    return (
      <div
        className="related-outer"
        style={{
          width: "100%",
          backgroundColor: "white",
        }}
      >
        <div className="related container px-sm-4">
          <div className="related__inner">
            <div className="related__item related__item-home">
              <a
                target="_blank"
                rel="noreferrer"
                href={
                  this.context.language === "en"
                    ? this.state.sectionData &&
                      this.state.sectionData[this.props.order].en.product1Link
                    : this.state.sectionData &&
                      this.state.sectionData[this.props.order].ka &&
                      this.state.sectionData[this.props.order].ka.product1Link
                    ? this.state.sectionData[this.props.order].ka.product1Link
                    : this.state.sectionData &&
                      this.state.sectionData[this.props.order].en.product1Link
                }
              >
                <div
                  className="related__image"
                  style={{
                    backgroundImage: `url(${
                      this.state.sectionData &&
                      this.state.sectionData[this.props.order].en.product1Img
                    })`,
                  }}
                ></div>
              </a>

              <div className="related__text">
                <div className="related__info">
                  {this.context.language === "en"
                    ? "Related products"
                    : "დაკავშირებული პროდუქტები"}
                </div>
                <div className="related__add">
                  
                  <div>{sectionData?.product1Title}</div>
                </div>
              </div>
            </div>

            <div className="related__item related__item-home">
              <a
                target="_blank"
                rel="noreferrer"
                href={
                  this.context.language === "en"
                    ? this.state.sectionData &&
                      this.state.sectionData[this.props.order].en.product2Link
                    : this.state.sectionData &&
                      this.state.sectionData[this.props.order].ka &&
                      this.state.sectionData[this.props.order].ka.product2Link
                    ? this.state.sectionData[this.props.order].ka.product2Link
                    : this.state.sectionData &&
                      this.state.sectionData[this.props.order].en.product2Link
                }
              >
                <div
                  className="related__image"
                  style={{
                    backgroundImage: `url(${
                      this.state.sectionData &&
                      this.state.sectionData[this.props.order].en.product2Img
                    })`,
                  }}
                ></div>
              </a>
              <div className="related__text">
                <div className="related__info">
                  {this.context.language === "en"
                    ? "Related products"
                    : "დაკავშირებული პროდუქტები"}
                </div>
                <div className="related__add">
                  
                  <div>{sectionData?.product2Title}</div>
                </div>
              </div>
            </div>

            <div className="related__item related__item-home">
              <a
                target="_blank"
                rel="noreferrer"
                href={
                  this.context.language === "en"
                    ? this.state.sectionData &&
                      this.state.sectionData[this.props.order].en.product3Link
                    : this.state.sectionData &&
                      this.state.sectionData[this.props.order].ka &&
                      this.state.sectionData[this.props.order].ka.product3Link
                    ? this.state.sectionData[this.props.order].ka.product3Link
                    : this.state.sectionData &&
                      this.state.sectionData[this.props.order].en.product3Link
                }
              >
                <div
                  className="related__image"
                  style={{
                    backgroundImage: `url(${
                      this.state.sectionData &&
                      this.state.sectionData[this.props.order].en.product3Img
                    })`,
                  }}
                ></div>
              </a>
              <div className="related__text">
                <div className="related__info">
                  {this.context.language === "en"
                    ? "Related products"
                    : "დაკავშირებული პროდუქტები"}
                </div>
                <div className="related__add">
                  
                  <div>{sectionData?.product3Title}</div>
                </div>
              </div>
            </div>
          </div>

          <a
            target="_blank"
            rel="noreferrer"
            href={
              this.context.language === "en"
                ? this.state.sectionData &&
                  this.state.sectionData[this.props.order].en.link
                : this.state.sectionData &&
                  this.state.sectionData[this.props.order].ka &&
                  this.state.sectionData[this.props.order].ka.link
                ? this.state.sectionData[this.props.order].ka.link
                : this.state.sectionData &&
                  this.state.sectionData[this.props.order].en.link
            }
            className="related__viewmore inner-link"
          >
            <div className="related__viewmore-inner">
              {this.context.language === "en" ? "View more" : "იხილეთ მეტი"}
              <span className="lnr lnr-arrow-right"></span>
            </div>
          </a>
        </div>
      </div>
    );
  }
}

export default RelatedProducts;
