import React, { useEffect, useState, useRef } from "react";
import $ from "jquery";
import "./cursor.scss";
import { Portal } from "react-portal"; //portal is used to render cursor
//as a sibling of root element because lightbox (ModalGateway) in ProjectDetail
//also renders outside of root element and does not obey z-index
// and the cursor could not be seen otherwise

//native cursor is used under 992px width (found in media query in scss file)

const Cursor = () => {
  const [position, setPosition] = useState({ x: 600, y: 400 });
  const [cursorType, setCursorType] = useState("regular");
  const [hidden, setHidden] = useState(false);
  const cursorEl = useRef(null);

  useEffect(() => {
    let listenToMouseMove = true;

    const onMouseMove = (e) => {
      if (listenToMouseMove) {
        setPosition({
          x: e.clientX,
          y: e.clientY,
        });
      }
    };

    const addEventListeners = () => {
      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseenter", onMouseEnter);
      document.addEventListener("mouseleave", onMouseLeave);
    };

    const removeEventListeners = () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseenter", onMouseEnter);
      document.removeEventListener("mouseleave", onMouseLeave);
    };

    //MAGNETIC BUTTONS  AND ATTRACTED CURSOR

    const magneticButtonsHover = () => {
      const cursorElm = cursorEl.current;

      $(document).on("mouseenter", ".magnetic", (e) => {
        listenToMouseMove = false;
        cursorElm.classList.add("attracted");

        const { x: buttonLeft, y: buttonTop } =
          e.target.getBoundingClientRect();
        let buttonWidth = e.target.offsetWidth / 2;
        let buttonHeight = e.target.offsetHeight / 2;

        cursorElm.style.transform = `translate3d(${
          buttonLeft + buttonWidth
        }px, ${buttonTop + buttonHeight}px, 0)`;
        setCursorType("attracted");
      });

      $(document).on("mouseleave", ".magnetic", (e) => {
        listenToMouseMove = true;
        cursorElm.classList.remove("attracted");

        setCursorType("regular");
      });

      $(document).on("click", ".magnetic:not(.special-magnetic)", () => {
        listenToMouseMove = true;
        cursorElm.classList.remove("attracted");

        setCursorType("regular");
      });
    };

    addEventListeners();
    leaveBody();
    magneticButtonsHover();
    handleDiagonalHover();
    handleLightBoxHover();
    handleNextProject();
    projectDetailLinkHover();
    handleOutsideLinkHover();
    handleInnerLinkHoverEvents();
    handleSliderHoverEvents();
    handlePlus();
    handleVideoHover();

    return () => removeEventListeners();
  }, []);

  const onMouseLeave = (e) => {
    setHidden(true);
  };
  const onMouseEnter = (e) => {
    setHidden(false);
  };

  const leaveBody = () => {
    $(document).on("mouseenter", "#root, .fullscreen", () => {
      setHidden(false);
    });

    $(document).on("mouseleave", "#root", () => {
      setHidden(true);
    });
  };

  ////////////////////////////  SOUND-ON - SOUND-OFF
  const handleVideoHover = () => {
    $(document).on("mouseenter", ".overlay", () => {
      const video = document.getElementById("home-video");
      if (video && video.muted) {
        setCursorType("soundoff");
      } else if (video) {
        setCursorType("soundon");
      }
    });

    $(document).on("mouseleave", ".overlay", () => {
      setCursorType("regular");
    });

    $(document).on(
      "click",
      ".slider__close-btn, .burger-container, .welcome__overlay-menu, .overlay-topbar, .overlay-topbar__burger, .overlay-topbar__logo, .overlay-topbar__shop, .slider__central, .slider__contents",
      (e) => {
        e.stopPropagation();
      }
    );

    $(document).on("click", ".overlay", (e) => {
      const video = document.getElementById("home-video");
      if (video && video.muted) {
        setCursorType("soundoff");
      } else if (video) {
        setCursorType("soundon");
      }
    });
  };

  /////////INNER LINK CURSOR

  const handleInnerLinkHoverEvents = () => {
    $(document).on("mouseenter", ".inner-link", () => {
      setCursorType("navLinkHovered");
    });

    $(document).on("mouseleave", ".inner-link", () => {
      setCursorType("regular");
    });

    $(document).on("click", ".inner-link", (e) => {
      setCursorType("regular");
    });
  };

  /////ARROW DOWN
  const handleNextProject = () => {
    $(document).on(
      "mouseenter",
      ".projectdetail__next-web, .scroll-down-button",
      () => {
        setCursorType("nextProject");
      }
    );

    $(document).on(
      "mouseleave",
      ".projectdetail__next-web, .scroll-down-button",
      () => {
        setCursorType("regular");
      }
    );

    $(document).on(
      "click",
      ".projectdetail__next-web, .scroll-down-button",
      () => {
        setCursorType("regular");
      }
    );
  };

  //////////EXTERNAL LINK CURSOR

  const handleOutsideLinkHover = () => {
    $(document).on(
      "mouseenter",
      "a:not(.nav-link,.magnetic, .inner-link,.topbar__shop,.overlay-topbar__shop), .events__main:not(.events__thumbnail)",
      (e) => {
        setCursorType("outsideLinkHovered");
      }
    );

    $(document).on(
      "mouseleave",
      "a:not(.nav-link,.magnetic, .inner-link), .events__main:not(.events__thumbnail)",
      (e) => {
        setCursorType("regular");
      }
    );
  };

  /////////////////PLUS

  const handlePlus = () => {
    $(document).on("mouseenter", ".cursor-plus", () => {
      setCursorType("plus");
    });

    $(document).on("mouseleave", ".cursor-plus", () => {
      setCursorType("regular");
    });
  };

  const handleDiagonalHover = () => {
    $(document).on("mouseenter", ".projectdetail__gallery img", () => {
      setCursorType("diagonalHover");
    });

    $(document).on("mouseleave", ".projectdetail__gallery img", () => {
      setCursorType("regular");
    });
  };

  const projectDetailLinkHover = () => {
    $(document).on(
      "mouseenter",
      ".interiors__text, .interiors__text-item",
      () => {
        setCursorType("outsideLinkHovered");
      }
    );

    $(document).on(
      "mouseleave",
      ".interiors__text, .interiors__text-item",
      () => {
        setCursorType("regular");
      }
    );
  };

  const handleSliderHoverEvents = () => {
    $(document).on("mouseenter", ".gallery-cursor", () => {
      setCursorType("sliderHovered");
    });

    $(document).on("mouseleave", ".gallery-cursor", () => {
      setCursorType("regular");
    });
  };

  //LIGHTBOX

  const handleLightBoxHover = () => {
    $(document).on("mouseenter", "#lightboxBackdrop", () => {
      setHidden(false);
      setCursorType("canBeClosed");
    });

    $(document).on("click", "#lightboxBackdrop", () => {
      setTimeout(() => setCursorType("regular"), 100);
    });
  };

  return (
    <Portal>
      <div
        id="cursor"
        className={`active ${hidden ? "cursor-hidden" : "cursor--visible"}`}
      >
        <div
          ref={cursorEl}
          className="cursor-inner"
          style={{
            transform: `translate3d(${position.x}px, ${position.y}px, 0px)`,
          }}
        >
          <div
            className={`cursor-inner__square ${
              cursorType === "regular" ? "cursor-inner--visible" : ""
            }`}
          >
            <svg
              id="Component_19_1"
              data-name="Component 19 – 1"
              xmlns="http://www.w3.org/2000/svg"
              width="76.7"
              height="76.7"
              viewBox="0 0 76.7 76.7"
            >
              <path
                id="Path_97"
                data-name="Path 97"
                d="M1137.1,464.2a36.9,36.9,0,1,0,36.9,36.9A36.9,36.9,0,0,0,1137.1,464.2Z"
                transform="translate(-1098.75 -462.75)"
                fill="none"
              />
              <path
                id="Path_98"
                data-name="Path 98"
                d="M1137.1,462.75a38.35,38.35,0,1,0,38.35,38.35A38.393,38.393,0,0,0,1137.1,462.75Zm36.65,38.35a36.65,36.65,0,1,1-36.65-36.65A36.691,36.691,0,0,1,1173.75,501.1Z"
                transform="translate(-1098.75 -462.75)"
              />
              <rect
                id="Rectangle_71"
                data-name="Rectangle 71"
                width="6"
                height="6"
                transform="translate(35.25 35.25)"
              />
            </svg>
          </div>

          <div
            className={`cursor-inner__plus ${
              cursorType === "plus"
                ? "cursor-inner--block"
                : "cursor-inner--none"
            }`}
          >
            <svg
              id="Component_14_1"
              data-name="Component 14 – 1"
              xmlns="http://www.w3.org/2000/svg"
              width="87"
              height="91.333"
              viewBox="0 0 87 91.333"
            >
              <path
                id="Path_100"
                data-name="Path 100"
                d="M1027.333,453.646c-23.1,0-41.832,20.072-41.832,44.832s18.729,44.832,41.832,44.832,41.832-20.072,41.832-44.832C1069.142,473.728,1050.427,453.671,1027.333,453.646Zm9.2,45.721h-8.438v8.9h-1.66v-8.9h-8.3v-1.779h8.3v-8.894h1.66v8.894h8.438Z"
                transform="translate(-983.833 -451.978)"
                fill="none"
              />
              <path
                id="Path_101"
                data-name="Path 101"
                d="M1033.821,500.161v-9.416h-1.883v9.416h-9.416v1.883h9.416v9.416h1.883v-9.416h9.573v-1.883Z"
                transform="translate(-989.458 -457.453)"
              />
              <g id="Group_89" data-name="Group 89">
                <path
                  id="Path_102"
                  data-name="Path 102"
                  d="M1027.333,453.646a41.832,41.832,0,1,0,41.832,41.832A41.832,41.832,0,0,0,1027.333,453.646Z"
                  transform="translate(-983.833 -451.978)"
                  fill="none"
                />
                <g id="Group_88" data-name="Group 88">
                  <path
                    id="Path_103"
                    data-name="Path 103"
                    d="M1027.333,452.1a43.375,43.375,0,1,0,43.375,43.375A43.375,43.375,0,0,0,1027.333,452.1Zm0,85.384a42.009,42.009,0,1,1,42.009-42.009A42.009,42.009,0,0,1,1027.333,537.487Z"
                    transform="translate(-983.833 -451.978)"
                  />
                  <path
                    id="Path_104"
                    data-name="Path 104"
                    d="M1027.333,538.978a43.5,43.5,0,0,1,0-87h0a43.5,43.5,0,1,1,0,87Zm0-86.779a43.34,43.34,0,1,0,30.584,12.694,43.327,43.327,0,0,0-30.584-12.694Zm0,85.412a42.134,42.134,0,1,1,42.134-42.134A42.182,42.182,0,0,1,1027.333,537.612Zm0-84.046a41.912,41.912,0,1,0,41.912,41.912A41.96,41.96,0,0,0,1027.333,453.566Z"
                    transform="translate(-983.833 -451.978)"
                  />
                </g>
              </g>
            </svg>
          </div>

          <div
            className={`cursor-inner__close ${
              cursorType === "canBeClosed"
                ? "cursor-inner--block cursor-close-btn"
                : "cursor-inner--none"
            }`}
          >
            <svg
              id="Component_20_1"
              data-name="Component 20 – 1"
              xmlns="http://www.w3.org/2000/svg"
              width="76.7"
              height="76.7"
              viewBox="0 0 76.7 76.7"
            >
              <g
                id="Group_175"
                data-name="Group 175"
                transform="translate(-1137.64 -631.875)"
              >
                <g
                  id="Group_198"
                  data-name="Group 198"
                  transform="translate(1137.64 631.875)"
                >
                  <path
                    id="Path_97"
                    data-name="Path 97"
                    d="M1137.1,464.2a36.9,36.9,0,1,0,36.9,36.9A36.9,36.9,0,0,0,1137.1,464.2Z"
                    transform="translate(-1098.75 -462.75)"
                    fill="none"
                  />
                  <path
                    id="Path_98"
                    data-name="Path 98"
                    d="M1137.1,462.75a38.35,38.35,0,1,0,38.35,38.35A38.393,38.393,0,0,0,1137.1,462.75Zm36.65,38.35a36.65,36.65,0,1,1-36.65-36.65A36.691,36.691,0,0,1,1173.75,501.1Z"
                    transform="translate(-1098.75 -462.75)"
                  />
                </g>
              </g>
              <path
                id="Path_140"
                data-name="Path 140"
                d="M1033.821,500.161v-9.416h-1.883v9.416h-9.416v1.883h9.416v9.416h1.883v-9.416h9.573v-1.883Z"
                transform="translate(-1046.537 414.431) rotate(-45)"
              />
            </svg>
          </div>

          <div
            className={`cursor-inner__arrows ${
              cursorType === "sliderHovered"
                ? "cursor-inner--block"
                : "cursor-inner--none"
            }`}
          >
            <svg
              id="Component_17_1"
              data-name="Component 17 – 1"
              xmlns="http://www.w3.org/2000/svg"
              width="101.253"
              height="76.7"
              viewBox="0 0 101.253 76.7"
            >
              <path
                id="Path_97"
                data-name="Path 97"
                d="M1137.1,464.2a36.9,36.9,0,1,0,36.9,36.9A36.9,36.9,0,0,0,1137.1,464.2Z"
                transform="translate(-1086.722 -462.75)"
                fill="none"
              />
              <path
                id="Path_98"
                data-name="Path 98"
                d="M1137.1,462.75a38.35,38.35,0,1,0,38.35,38.35A38.393,38.393,0,0,0,1137.1,462.75Zm36.65,38.35a36.65,36.65,0,1,1-36.65-36.65A36.691,36.691,0,0,1,1173.75,501.1Z"
                transform="translate(-1086.722 -462.75)"
              />
              <rect
                id="Rectangle_71"
                data-name="Rectangle 71"
                width="6"
                height="6"
                transform="translate(47.278 35.25)"
              />
              <path
                id="Path_116"
                data-name="Path 116"
                d="M0,0,8.664,8.664H0Z"
                transform="translate(95.126 44.876) rotate(-135)"
              />
              <path
                id="Path_117"
                data-name="Path 117"
                d="M0,0,8.664,8.664H0Z"
                transform="translate(6.126 32.624) rotate(45)"
              />
            </svg>
          </div>

          <div
            className={`cursor-inner__link ${
              cursorType === "outsideLinkHovered" ? "cursor-inner--visible" : ""
            }`}
          >
            <svg
              id="Component_15_1"
              data-name="Component 15 – 1"
              xmlns="http://www.w3.org/2000/svg"
              width="87"
              height="87"
              viewBox="0 0 87 87"
            >
              <path
                id="Path_105"
                data-name="Path 105"
                d="M928.422,491.327H913.265v1.965h12.746l-16.666,16.665-.083.084,1.353,1.5,16.759-16.758V507.4h1.964V492.262a.623.623,0,0,0-.1-.554A1,1,0,0,0,928.422,491.327Z"
                transform="translate(-875.833 -457.851)"
              />
              <path
                id="Path_106"
                data-name="Path 106"
                d="M944.453,463.884a43.475,43.475,0,1,0,12.758,30.741A43.288,43.288,0,0,0,944.453,463.884Zm11.171,30.741a41.912,41.912,0,1,1-41.912-41.912A41.96,41.96,0,0,1,955.623,494.625Z"
                transform="translate(-870.211 -451.125)"
              />
            </svg>
          </div>

          <div
            className={`cursor-inner__nav-link ${
              cursorType === "navLinkHovered" ? "cursor-inner--visible" : ""
            }`}
          >
            <svg
              id="Component_16_1"
              data-name="Component 16 – 1"
              xmlns="http://www.w3.org/2000/svg"
              width="87"
              height="87"
              viewBox="0 0 87 87"
            >
              <path
                id="Path_107"
                data-name="Path 107"
                d="M932.421,625.163l-10.035-10.035-.089-.088-1.32,1.321,8.5,8.5H902.864v1.922H929.39l-8.326,8.325-.087.089,1.32,1.322,10.11-10.111a.594.594,0,0,0,.3-.442A.959.959,0,0,0,932.421,625.163Z"
                transform="translate(-874.293 -582.28)"
              />
              <path
                id="Path_108"
                data-name="Path 108"
                d="M942.91,589.413a43.475,43.475,0,1,0,12.758,30.742A43.291,43.291,0,0,0,942.91,589.413Zm11.171,30.742a41.912,41.912,0,1,1-41.912-41.912A41.96,41.96,0,0,1,954.08,620.155Z"
                transform="translate(-868.668 -576.655)"
              />
            </svg>
          </div>

          <div
            className={`cursor-inner__nav-link next-project-cursor ${
              cursorType === "nextProject" ? "cursor-inner--visible" : "hidden"
            }`}
          >
            <svg
              id="Component_16_1"
              data-name="Component 16 – 1"
              xmlns="http://www.w3.org/2000/svg"
              width="87"
              height="87"
              viewBox="0 0 87 87"
            >
              <path
                id="Path_107"
                data-name="Path 107"
                d="M932.421,625.163l-10.035-10.035-.089-.088-1.32,1.321,8.5,8.5H902.864v1.922H929.39l-8.326,8.325-.087.089,1.32,1.322,10.11-10.111a.594.594,0,0,0,.3-.442A.959.959,0,0,0,932.421,625.163Z"
                transform="translate(-874.293 -582.28)"
              />
              <path
                id="Path_108"
                data-name="Path 108"
                d="M942.91,589.413a43.475,43.475,0,1,0,12.758,30.742A43.291,43.291,0,0,0,942.91,589.413Zm11.171,30.742a41.912,41.912,0,1,1-41.912-41.912A41.96,41.96,0,0,1,954.08,620.155Z"
                transform="translate(-868.668 -576.655)"
              />
            </svg>
          </div>

          <div
            className={`cursor-outline ${
              cursorType === "attracted" ? "cursor-inner--visible" : "hidden"
            }`}
          >
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="76.7"
              height="76.7"
              viewBox="0 0 76 76"
              style={{ overflow: "visible", enableBackground: "new 0 0 76 76" }}
              xml="preserve"
            >
              <defs></defs>
              <path
                id="Path_166_1_"
                d="M38,0C17.01,0,0,17.01,0,38s17.01,38,38,38s38-17.01,38-38C75.98,17.02,58.98,0.02,38,0z M74.32,38
          c0,20.06-16.26,36.32-36.32,36.32S1.68,58.06,1.68,38S17.94,1.68,38,1.68C58.05,1.71,74.29,17.95,74.32,38z"
              />
            </svg>
          </div>

          <div
            className={`cursor-diagonal ${
              cursorType === "diagonalHover"
                ? "cursor-inner--visible"
                : "hidden"
            }`}
          >
            <svg
              id="Component_18_1"
              data-name="Component 18 – 1"
              xmlns="http://www.w3.org/2000/svg"
              width="77.009"
              height="77.157"
              viewBox="0 0 77.009 77.157"
            >
              <g
                id="Group_174"
                data-name="Group 174"
                transform="translate(-1137.331 -631.875)"
              >
                <g
                  id="Group_197"
                  data-name="Group 197"
                  transform="translate(1137.331 631.875)"
                >
                  <path
                    id="Path_97"
                    data-name="Path 97"
                    d="M1137.1,464.2a36.9,36.9,0,1,0,36.9,36.9A36.9,36.9,0,0,0,1137.1,464.2Z"
                    transform="translate(-1098.441 -462.75)"
                    fill="none"
                  />
                  <path
                    id="Path_98"
                    data-name="Path 98"
                    d="M1137.1,462.75a38.35,38.35,0,1,0,38.35,38.35A38.393,38.393,0,0,0,1137.1,462.75Zm36.65,38.35a36.65,36.65,0,1,1-36.65-36.65A36.691,36.691,0,0,1,1173.75,501.1Z"
                    transform="translate(-1098.441 -462.75)"
                  />
                  <path
                    id="Path_116"
                    data-name="Path 116"
                    d="M0,0,8.664,8.664H0Z"
                    transform="translate(76.739 9.082) rotate(180)"
                  />
                  <path
                    id="Path_117"
                    data-name="Path 117"
                    d="M0,0,8.664,8.664H0Z"
                    transform="matrix(1, 0.017, -0.017, 1, 0.151, 68.343)"
                  />
                </g>
                <rect
                  id="Rectangle_110"
                  data-name="Rectangle 110"
                  width="6"
                  height="6"
                  transform="translate(1172.89 667.125)"
                />
              </g>
            </svg>
          </div>

          <div
            className={`cursor-sound-on ${
              cursorType === "soundon" ? "cursor-inner--visible" : "hidden"
            }`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="76.7"
              height="76.7"
              viewBox="0 0 76.7 76.7"
            >
              <g
                id="Group_247"
                data-name="Group 247"
                transform="translate(-764 -417)"
              >
                <g id="Group_243" data-name="Group 243">
                  <path
                    id="Path_166"
                    data-name="Path 166"
                    d="M1137.1,462.75a38.35,38.35,0,1,0,38.35,38.35A38.393,38.393,0,0,0,1137.1,462.75Zm36.65,38.35a36.65,36.65,0,1,1-36.65-36.65A36.691,36.691,0,0,1,1173.75,501.1Z"
                    transform="translate(-334.75 -45.75)"
                    fill="#000"
                  />
                </g>
                <g
                  id="noun_Sound_1779532"
                  transform="translate(780.491 426.923)"
                >
                  <g
                    id="Group_245"
                    data-name="Group 245"
                    transform="translate(11 16.5)"
                  >
                    <path
                      id="Path_168"
                      data-name="Path 168"
                      d="M26.665,40.353V16.5l-9.4,7.3H11v9.256h6.266Z"
                      transform="translate(-11 -16.5)"
                      fill="#000"
                    />
                    <path
                      id="Path_169"
                      data-name="Path 169"
                      d="M62,34.7v1.638a4.79,4.79,0,0,1,0,7.975V45.95A6.224,6.224,0,0,0,62,34.7Z"
                      transform="translate(-43.843 -28.22)"
                      fill="#000"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </div>

          <div
            className={`cursor-sound-off ${
              cursorType === "soundoff" ? "cursor-inner--visible" : "hidden"
            }`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="76.7"
              height="76.701"
              viewBox="0 0 76.7 76.701"
            >
              <path
                id="Union_1"
                data-name="Union 1"
                d="M-1436,38.35A38.394,38.394,0,0,1-1397.651,0a38.471,38.471,0,0,1,38.35,38.35,38.393,38.393,0,0,1-38.35,38.351A38.393,38.393,0,0,1-1436,38.35Zm1.7,0A36.692,36.692,0,0,0-1397.651,75,36.692,36.692,0,0,0-1361,38.35,36.747,36.747,0,0,0-1397.651,1.7,36.691,36.691,0,0,0-1434.3,38.35Zm27.976,4.629h-6.266V33.722h6.266l9.4-7.3V50.277Zm16.8-3.609-3.817,3.816-1.02-1.02,3.817-3.816-3.817-3.816,1.02-1.02,3.817,3.816,3.816-3.816,1.02,1.02-3.816,3.816,3.816,3.816-1.02,1.02Z"
                transform="translate(1436)"
                fill="#000"
              />
            </svg>
          </div>
        </div>
      </div>
    </Portal>
  );
};

export default Cursor;
