import React from "react";
import { LangContext } from "../../languageContext";
import "./footer.scss";

class Footer extends React.Component {
  static contextType = LangContext;

  state = {
    data: null,
  };

  componentDidMount = () => {
    this.getContactData();
  };

  async getContactData() {
    try {
      const response = await fetch(
        "https://n1.noxtton.com/motif-contact-and-hero-block",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-api-key": "e17fd67932e135afbe858ad03882d826feaf0124e972d08d",
          },
        }
      );
      const json = await response.json();
      this.setState({ data: json });
    } catch (err) {
      console.log(err);
    }
  }

  handleSubmit(e) {
    setTimeout(() => {
      e.target.reset();
    }, 1000);
  }

  render() {
    const val = this.state.data
      ? this.state.data[0][this.context.language]
      : null;

    return (
      <div className="footer">
        <div className="footer-inner">
          {/* Begin Mailchimp Signup Form */}
          <div id="mc_embed_signup" className="email">
            <form
              action="https://motif.us1.list-manage.com/subscribe/post?u=518e24fcb99095851fa4ae36c&amp;id=4c14eb3e0a"
              method="post"
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              className="validate"
              target="_blank"
              noValidate
              onSubmit={this.handleSubmit}
            >
              <div id="mc_embed_signup_scroll" className="wrapper">
                <p>
                  {this.context.language === "en"
                    ? "Sign up for news"
                    : "გამოიწერეთ სიახლეები"}
                </p>
                <div className="input-data mc-field-group">
                  <input
                    type="email"
                    name="EMAIL"
                    id="mce-EMAIL"
                    style={{ outline: "none !important" }}
                    onChange={(e) => {
                      e.target.value.length
                        ? e.target.classList.add("dirty")
                        : e.target.classList.remove("dirty");
                    }}
                  />
                  <div className="underline"></div>
                  <label>
                    {this.context.language === "en" ? "Email" : "ელ-ფოსტა"}
                  </label>

                  <button
                    type="submit"
                    value="Subscribe"
                    name="subscribe"
                    id="mc-embedded-subscribe"
                    className="magnetic footer-email-button"
                  >
                    <span
                      className="lnr lnr-arrow-right"
                      style={{ color: "white" }}
                    ></span>
                  </button>
                </div>
                <div id="mce-responses" className="clear">
                  <div
                    className="response"
                    id="mce-error-response"
                    style={{ display: "none" }}
                  ></div>
                  <div
                    className="response"
                    id="mce-success-response"
                    style={{ display: "none" }}
                  ></div>
                </div>
                {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
                <div
                  style={{ position: "absolute", left: "-5000px" }}
                  aria-hidden="true"
                >
                  <input
                    type="text"
                    name="b_518e24fcb99095851fa4ae36c_4c14eb3e0a"
                    tabIndex="-1"
                    value=""
                    readOnly
                  />
                </div>
                <div className="clear"></div>
              </div>
            </form>
          </div>

          {/* End mc_embed_signup */}

          <div className="contact-container">
            <div className="contact">
              <div className="contact__address">
                <div>{val?.address1line1}</div>
                <div>{val?.address1line2}</div>
              </div>
              <div className="contact__small-underline"></div>
              <div className="contact__phone">
                <div>{this.state.data?.[0].en.address1phonenumber}</div>
                <div>{this.state.data?.[0].en.address1email}</div>
              </div>
            </div>

            <div className="contact">
              <div className="contact__address">
                <div>{val?.address2line1}</div>
                <div>{val?.address2line2}</div>
              </div>
              <div className="contact__small-underline"></div>
              <div className="contact__phone">
                <div>{this.state.data?.[0].en.address2phonenumber}</div>
                <div>{this.state.data?.[0].en.address2email}</div>
              </div>
            </div>

            <div className="contact">
              <div className="contact__address">
                <div>{val?.address3line1}</div>
                <div>{val?.address3line2}</div>
              </div>
              <div className="contact__small-underline"></div>
              <div className="contact__phone">
                <div>{this.state.data?.[0].en.address3phonenumber}</div>
                <div>{this.state.data?.[0].en.address3email}</div>
              </div>
            </div>
          </div>

          <div className="media">
            <div>
              <a
                target="_blank"
                rel="noreferrer"
                href={`${this.state.data?.[0].en.Link2}`}
              >
                {this.state.data?.[0].en.Link2name}
              </a>
            </div>
            <div>
              <a
                target="_blank"
                rel="noreferrer"
                href={`${this.state.data?.[0].en.Link1}`}
              >
                {this.state.data?.[0].en.Link1name}
              </a>
            </div>
            <div>
              <a
                target="_blank"
                rel="noreferrer"
                href={`${this.state.data?.[0].en.Link3}`}
              >
                {this.state.data?.[0].en.Link3name}
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
