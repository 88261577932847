import React from "react";
import { Link } from "react-router-dom";
import { LangContext } from "../../../languageContext";
import "./project-detail.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ReactComponent as NextButton } from "../../../assets/components/cursor-next.svg";
import GalleryAndLightbox from "./galleryAndLightbox";

export default class ProjectDetail extends React.Component {
  static contextType = LangContext;

  viewedProjectsIndexes = [];

  settings = {
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    arrows: false,
    afterChange: (index) => {
      this.setState({ currentSlide: index });
    },
  };

  constructor(props) {
    super(props);

    this.state = {
      slug: this.props.match.params.slug,
      data: null,
      project: null,
      nextData: null,
      currentSlide: 0,
      currentImage: 0,
      viewerIsOpen: false,
      slides: [],
      images: [],
      curreentIndex: null,
    };
  }

  openLightBox = (event, { photo, index }) => {
    this.setState({
      currentImage: index,
      viewerIsOpen: true,
    });
  };

  closeLightBox = () => {
    this.setState({
      viewerIsOpen: false,
    });
  };

  componentDidMount = () => {
    this.loadProjectData();
  };

  componentDidUpdate = () => {
    const slug = this.props.match.params.slug.replace(/-/g, " ");
    if (this.state.slug !== slug) {
      this.setState({ slug }, this.displayProject);
    }
    
  };

  async loadProjectData() {
    try {
      const response = await fetch("https://n1.noxtton.com/motif-project", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-api-key": "e17fd67932e135afbe858ad03882d826feaf0124e972d08d",
        },
      });
      const json = await response.json();
      if (json) {
        this.setState({ data: json }, this.displayProject);
      }
    } catch (err) {
      console.log(err);
    }
  }

  displayProject = () => {
    const json = this.state.data;

    const key = Object.keys(json).find(
      (item) =>
        json[item].en.title.toUpperCase() === this.state.slug.toUpperCase()
    );

    const projectData = json[key];
    this.setState({ project: projectData }, this.createSlides);

    //if we ever need chronological next project
    // let index = json.indexOf(json[key]);
    // const nextProjectData = json[(index + 1) % json.length];

    //random next project:
    const currentIndex = json.indexOf(json[key]);
    this.viewedProjectsIndexes.push(currentIndex);
    const lastIndexes = this.viewedProjectsIndexes.slice(Math.max(this.viewedProjectsIndexes.length - 2, 0)); //getting last two indexes from viewed indexes

    let randomIndex = 0;
    while (true) {
      randomIndex = Math.floor(Math.random() * json.length);
      if (randomIndex !== lastIndexes[0] && randomIndex !== lastIndexes[1] && randomIndex !== -1) {
        break;
      }
    }
    const nextProjectData = json[randomIndex];

    this.setState({ nextData: nextProjectData }, this.createNextSlug);
  };

  createSlides = () => {
    if (this.state.project) {
      let data = this.state.project.en;

      this.setState({
        slides: [
          {
            id: 1,
            source: data.gallery1Full,
          },
          {
            id: 2,
            source: data.gallery2Full,
          },
          {
            id: 3,
            source: data.gallery3Full,
          },
          {
            id: 4,
            source: data.gallery4Full,
          },
          {
            id: 5,
            source: data.gallery5Full,
          },
          {
            id: 6,
            source: data.gallery6Full,
          },
        ],
        images: [
          [data.gallery1Full, data.gallery1Thumb],
          [data.gallery2Full, data.gallery2Thumb],
          [data.gallery3Full, data.gallery3Thumb],
          [data.gallery4Full, data.gallery4Thumb],
          [data.gallery5Full, data.gallery5Thumb],
          [data.gallery6Full, data.gallery6Thumb],
        ],
      });
    }
  };

  createNextSlug = () => {
    if (this.state.nextData) {
      let data = this.state.nextData;
      let nextSlug = data.en.title.toLowerCase().replace(/ /g, "-");
      this.setState({
        nextData: { ...data, slug: nextSlug },
      });
    }
  };

  createMarkup = (val) => {
    return { __html: val };
  };

  render() {
    let data = this.state.project
      ? this.state.project[this.context.language]
      : null;
    let nextData = this.state.nextData
      ? this.state.nextData[this.context.language]
      : null;

    return (
      <div className="projectdetail">
        <div className="projectdetail__intro">
          <div className="projectdetail__text1">
            <div className="site-path aboutus__site-path">
              <span>
                <Link to="/">
                  {this.context.language === "en" ? "Home" : "მთავარი გვერდი"}
                </Link>{" "}
                /{" "}
                <Link to="/projects">
                  {this.context.language === "en" ? "Projects" : "პროექტები"}
                </Link>{" "}
                / {this.state.project && this.state.project?.en.title}
              </span>
            </div>

            <div className="projectdetail__category">
              {this.context.language === "en" ? "Interior" : "ინტერიერი"}{" "}
              {this.state.project?.en.exterior === "true" ? (
                <>
                  <span>&</span>{" "}
                  {this.context.language === "en" ? "Exterior" : "ექსტერიერი"}
                </>
              ) : null}
            </div>
            <div className="projectdetail__name">
              {this.state.project && this.state.project?.en.title}
            </div>
            <div
              className="projectdetail__text1-body"
              dangerouslySetInnerHTML={this.createMarkup(data?.text1)}
            ></div>
          </div>
          <div
            className="projectdetail__hatimg"
            style={{
              backgroundImage: `url(${
                this.state.project &&
                this.state.project.en &&
                this.state.project?.en.hatImg
              })`,
            }}
          ></div>
        </div>
        <div className="projectdetail__description">
          <div
            className="projectdetail__text2"
            dangerouslySetInnerHTML={this.createMarkup(data?.text2)}
          ></div>
          <div className="projectdetail__overlaycontainer">
            <div
              className="projectdetail__overlayimg"
              style={{
                backgroundImage: `url(${
                  this.state.project &&
                  this.state.project.en &&
                  this.state.project?.en?.overlayImg
                })`,
              }}
            ></div>
            <div
              data-content={data?.overlayTitle}
              className="projectdetail__overlaytext"
            >
              {data?.overlayTitle}
            </div>
          </div>
        </div>
        <div className="projectdetail__text3">
          <div
            className="projectdetail__text3-body"
            dangerouslySetInnerHTML={this.createMarkup(data?.text3)}
          ></div>
        </div>

        <div className="projectdetail__gallery">
          {/* THIS ONLY SHOWS IN MOBILE */}
          <div className="projectdetail__inner-gallery">
            <Slider {...this.settings}>
              {this.state.slides
                ? this.state.slides.map((item) => (
                    <React.Fragment key={item?.id}>
                      <div
                        className="projectdetail__gallery-item"
                        style={{
                          backgroundImage: `url('${
                            item && item.source && item?.source
                          }')`,
                        }}
                      ></div>
                    </React.Fragment>
                  ))
                : null}
            </Slider>
            <div className="projectdetail__slides-num">
              <span>
                {this.state.currentSlide + 1} / {this.state.slides.length}
              </span>
            </div>
          </div>
          {/* END */}

          {/* THIS ONLY SHOWS IN DESKTOP */}
          <div className="projectdetail__innergallery-web">
            {this.state.images.length ? (
              <GalleryAndLightbox images={this.state.images} />
            ) : null}
          </div>

          {/* END */}

          <div className="projectdetail__share">
            <div>{this.context.language === "en" ? "Share" : "გააზიარეთ"}</div>
            <div>
              <a href="/" style={{ pointerEvents: "none" }}>
                Facebook<span className="lnr lnr-arrow-right"></span>
              </a>
            </div>
          </div>
        </div>

        {/* NEXT PROJECT */}

        {/* this only shows in mobile */}
        <div className="projectdetail__next">
          <Link
            to={`/projects/${this.state.nextData && this.state.nextData?.slug}`}
          >
            <div className="projectdetail__next-title">
              {this.context.language === "en"
                ? "Next Project"
                : "შემდეგი პროექტი"}
            </div>
            <div className="projectdetail__next-overlay">
              <div
                className="projectdetail__next-overlayimg"
                style={{
                  backgroundImage: `url('${
                    this.state.nextData && this.state.nextData?.en.hatImg
                  }')`,
                }}
              >
                <span>
                  <NextButton />
                </span>
                <div className="projectdetail__next-darkoverlay"></div>
              </div>
              <div className="projectdetail__next-overlaytext">
                <div className="projectdetail__next-category">
                  {this.context.language === "en" ? "Interior" : "ინტერიერი"}{" "}
                  {this.state.nextData?.en.exterior === "true" ? (
                    <>
                      <span>·</span>{" "}
                      {this.context.language === "en"
                        ? "Exterior"
                        : "ექსტერიერი"}
                    </>
                  ) : null}
                </div>
                <div
                  className="projectdetail__next-overlayname"
                  data-content={
                    this.state.nextData && this.state.nextData?.en.title
                  }
                >
                  {this.state.nextData && this.state.nextData?.en.title}
                </div>
              </div>
            </div>
          </Link>
        </div>
        {/* end */}

        {/* this only shows in web */}
        <div className="projectdetail__next-web">
          <Link
            to={`/projects/${this.state.nextData && this.state.nextData?.slug}`}
          >
            <div>
              {this.context.language === "en"
                ? "Next Project"
                : "შემდეგი პროექტი"}{" "}
              <span className="lnr lnr-arrow-down"></span>
            </div>
            <div className="projectdetail__intro next">
              <div className="projectdetail__text1">
                <div className="projectdetail__category">
                  {this.context.language === "en" ? "Interior" : "ინტერიერი"}{" "}
                  {this.state.nextData?.en.exterior === "true" ? (
                    <>
                      <span>&</span>{" "}
                      {this.context.language === "en"
                        ? "Exterior"
                        : "ექსტერიერი"}
                    </>
                  ) : null}
                </div>
                <div className="projectdetail__name">
                  {this.state.nextData && this.state.nextData?.en.title}
                </div>
                <div
                  className="projectdetail__text1-body"
                  dangerouslySetInnerHTML={this.createMarkup(nextData?.text1)}
                ></div>
              </div>
              <div
                className="projectdetail__hatimg"
                style={{
                  backgroundImage: `url(${
                    this.state.nextData &&
                    this.state.nextData.en &&
                    this.state.nextData?.en.hatImg
                  })`,
                }}
              ></div>
            </div>
            <div className="projectdetail__gradient-overlay"></div>
          </Link>
        </div>
        {/* end of this only shows in web */}
      </div>
    );
  }
}
