import React from "react";
import { LangContext } from "../../../languageContext";
import "./product-gallery.scss";

class ProductsGallery extends React.Component {
  static contextType = LangContext;

  splitArrayIntoChunksOfThree() {
    const products = this.props.data;
    const chunks = [];
    let i = 0;
    if (products.length) {
      while (i < products.length) {
        chunks.push(products.slice(i, (i += 3)));
      }
    }
    return chunks;
  }

  render() {
    const chunksOfThree = this.splitArrayIntoChunksOfThree();

    return (
      <div className="related container related__productspage px-sm-4">
        {chunksOfThree.length ? (
          chunksOfThree.map((item, index) => (
            <div
              className="related__inner related__inner-productspage"
              key={index}
            >
              <div className="related__item">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={
                    this.context.language === "en"
                      ? item[0] && item?.[0].en.link
                      : item[0] && item[0].ka && item?.[0].ka.link
                      ? item?.[0].ka.link
                      : item[0] && item?.[0].en.link
                  }
                >
                  <div
                    className="related__image"
                    style={{ backgroundImage: `url(${item?.[0].en.image})` }}
                  ></div>
                  <div className="related__text">
                    <div className="related__info">
                      {item?.[0][this.context.language].name}
                    </div>
                    <div className="related__add">
                      
                      <div>{item?.[0].en.brand}</div>
                    </div>
                  </div>
                </a>
              </div>

              <div
                className={
                  "related__item " + (item[1] ? "" : "product-invisible")
                }
              >
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={
                    this.context.language === "en"
                      ? item[1] && item?.[1].en.link
                      : item[1] && item[1].ka && item?.[1].ka.link
                      ? item?.[1].ka.link
                      : item[1] && item?.[1].en.link
                  }
                >
                  <div
                    className="related__image"
                    style={{
                      backgroundImage: `url(${item[1] && item?.[1].en.image})`,
                    }}
                  ></div>
                  <div className="related__text">
                    <div className="related__info">
                      {item[1] &&
                        item?.[1][this.context.language] &&
                        item?.[1][this.context.language].name}
                    </div>
                    {item[1] ? (
                      <div className="related__add">
                        
                        <div>{item[1] && item?.[1].en.brand}</div>
                      </div>
                    ) : null}
                  </div>
                </a>
              </div>

              <div
                className={
                  "related__item " + (item[2] ? "" : "product-invisible")
                }
              >
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={
                    this.context.language === "en"
                      ? item[2] && item?.[2].en.link
                      : item[2] && item[2].ka && item?.[2].ka.link
                      ? item?.[2].ka.link
                      : item[2] && item?.[2].en.link
                  }
                >
                  <div
                    className="related__image"
                    style={{
                      backgroundImage: `url(${item[2] && item?.[2].en.image})`,
                    }}
                  ></div>
                  <div className="related__text">
                    <div className="related__info">
                      {item[2] && item?.[2][this.context.language].name}
                    </div>
                    {item[2] ? (
                      <div className="related__add">
                        
                        <div>{item[2] && item?.[2].en.brand}</div>
                      </div>
                    ) : null}
                    {/* ternary operators in item 2 and item 3 not to mess layout */}
                  </div>
                </a>
              </div>
            </div>
          ))
        ) : (
          <div className="products-gallery__noresults">
            {this.context.language === "en"
              ? "No results were found"
              : "არაფერი მოიძებნა"}
          </div>
        )}
      </div>
    );
  }
}

export default ProductsGallery;
