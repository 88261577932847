import React from "react";
import { Link } from "react-router-dom";
import { LangContext } from "../../languageContext";
import ProductsGallery from "./products-gallery/product-gallery";
import Filter from "./filter/filter";
import "./products.scss";
import config from "../../env";

export default class Products extends React.Component {
  static contextType = LangContext;

  constructor(props) {
    super(props);
    this.state = {
      data: null,
      bannerData: null,
      taggedData: null,
      slug: this.props.match.params.slug,
      brands: null,
      designers: null,
      //     typeOpen: false,
      //     designerOpen: false,
      //     brandOpen: false,
      //     isDesktop: false,
      //     selectedProducts: [],
      //     selectedDesigners: [],
      //     selectedBrands: []
    };
  }

  componentDidMount = () => {
    this.loadProducts();
    this.loadDesigners();
    this.loadBrands();

    this.loadCover();
  };

  componentDidUpdate = () => {
    const slug = this.props.match.params.slug;
    if (this.state.slug !== slug) {
      this.setState({ slug }, this.displayDetail);
    }
  };

  async loadProducts() {
    try {
      const response = await fetch(
        `https://n1.noxtton.com/motif-products`,
        config
      );
      const json = await response.json();
      if (json) {
        this.setState({ data: json }, this.displayDetail);
      }
    } catch (err) {
      console.log(err);
    }
  }
  async loadBrands() {
    try {
      const response = await fetch(
        `https://n1.noxtton.com/motif-products-brands`,
        config
      );
      const json = await response.json();
      this.setState({ brands: json });
    } catch (err) {
      console.log(err);
    }
  }

  async loadDesigners() {
    try {
      const response = await fetch(
        `https://n1.noxtton.com/motif-products-designers`,
        config
      );
      const json = await response.json();
      this.setState({ designers: json });
    } catch (err) {
      console.log(err);
    }
  }

  async loadCover() {
    try {
      const response = await fetch(
        `https://n1.noxtton.com/motif-products-banner`,
        config
      );
      const json = await response.json();
      if (json) {
        this.setState({ bannerData: json });
      }
    } catch (err) {
      console.log(err);
    }
  }

  async displayDetail() {
    let taggedProductsArr = [];
    try {
      const json = this.state.data;

      if (this.state.slug === "all") {
        taggedProductsArr = json;
      } else {
        Object.keys(json).forEach((item) => {
          if (json[item].en.tags.includes(this.state.slug)) {
            taggedProductsArr.push(json[item]);
          }
        });
      }

      this.setState({ taggedData: taggedProductsArr });
    } catch (err) {
      console.log(err);
    }
  }

  updateDataFromFilter = (data) => {
    this.setState({ taggedData: data });
  };

  render() {
    let selectedBannerData = this.state.bannerData?.find((item) => {
      return (
        item.en.title.replace(/[^A-Z\d\s]/gi, "").toLowerCase() ===
        this.state.slug
      );
    });
    if (selectedBannerData === undefined) {
      selectedBannerData = this.state.bannerData?.find((item) => {
        return item.en.title.toLowerCase() === "products";
      });
    }
    return (
      <div className="products">
        <div
          className="products__welcome"
          style={{
            backgroundImage: `url(${selectedBannerData?.en.image})`,
          }}
        >
          <div className="products__overlay"></div>

          <div className="products__overlay-text">
            <div>{selectedBannerData?.[this.context.language].title}</div>
          </div>
        </div>

        <div className="products__contents">
          <div className="site-path products__site-path">
            <span>
              <Link to="/">
                {this.context.language === "en" ? "Home" : "მთავარი გვერდი"}
              </Link>{" "}
              / {this.context.language === "en" ? "Products" : "პროდუქტები"}
            </span>
          </div>

          <Filter
            brandsData={this.state.brands}
            designersData={this.state.designers}
            data={this.state.data}
            slug={this.state.slug}
            changeDisplayedData={this.updateDataFromFilter}
          />

          {this.state.taggedData ? (
            <ProductsGallery
              data={this.state.taggedData}
              slug={this.state.slug}
            />
          ) : null}
        </div>
      </div>
    );
  }
}
