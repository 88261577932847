import React from "react";
import { LangContext } from "../../languageContext";
import "./interiors.scss";
import { Link } from "react-router-dom";

export default class Interiors extends React.Component {
  static contextType = LangContext;

  state = {
    data: null,
  };

  componentDidMount = () => {
    this.getInteriorsData();
  };

  async getInteriorsData() {
    try {
      const response = await fetch("https://n1.noxtton.com/motif-interiors", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-api-key": "e17fd67932e135afbe858ad03882d826feaf0124e972d08d",
        },
      });
      const json = await response.json();
      this.setState({ data: json });
      this.createSlugs();
    } catch (err) {
      console.log(err);
    }
  }

  createSlugs = () => {
    if (this.state.data) {
      let val = this.state.data;
      val = val.map((item) => {
        let slug = item.en.coverTitle.toLowerCase().trim();
        return { ...item, slug: slug };
      });
      this.setState({
        data: val,
      });
    }
  };

  render() {
    const data = this.state.data ? this.state.data : null;

    return (
      <div className="interiors">
        <div className="interiors__inner">
          <div className="site-path">
            <span>
              <Link to="/">
                {this.context.language === "en" ? "Home" : "მთავარი გვერდი"}
              </Link>{" "}
              / {this.context.language === "en" ? "Interiors" : "ინტერიერი"}
            </span>
          </div>

          <div className="interiors__inner-content">
            {data
              ? data.map((item) => {
                  return (
                    <div
                      key={item?.id}
                      className="interiors__item"
                      style={{ backgroundImage: `url(${item?.en.sliderImg})` }}
                    >
                      <Link
                        to={`/interiors/${item?.slug}`}
                        className="inner-link"
                      >
                        <div className="interiors__overlay"></div>
                        <div className="interiors__text">
                          <div className="interiors__text-item">
                            {this.context.language === "en"
                              ? "Interior"
                              : "ინტერიერი"}{" "}
                            ·{" "}
                            {this.context.language === "en"
                              ? "Exterior"
                              : "ექსტერიერი"}
                          </div>
                          <div className="interiors__text-item">
                            {item?.[this.context.language].coverTitle ||
                              item?.en.coverTitle}
                          </div>
                        </div>
                      </Link>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      </div>
    );
  }
}
