import React from "react";
import { Link } from "react-router-dom";
import { LangContext } from "../../languageContext";
import { ReactComponent as Outline } from "../../assets/components/outline.svg";
import "./home.scss";
import Banner from "./scrolling-banner/banner";
import Events from "./events/events";
import Living from "./living/living";
import Gallery from "./gallery/gallery";
import OverlayMenu from "../menu/overlay-menu/overlay-menu";

class Home extends React.Component {
  static contextType = LangContext;



  constructor(props) {
    super(props);

    this.state = {
      heroBlockData: null,
      sectionData: null,
      video: null,
    };
  }



  async loadHeroBlockData() {
    try {
      const response = await fetch(
        "https://n1.noxtton.com/motif-home-hero-block",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-api-key": "e17fd67932e135afbe858ad03882d826feaf0124e972d08d",
          },
        }
      );
      const json = await response.json();
      if (json) {
        this.setState({ heroBlockData: json });
      }
    } catch (err) {
      console.log(err);
    }
  }

  async loadVideo() {
    try {
      const response = await fetch("https://n1.noxtton.com/motif-home-video", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-api-key": "e17fd67932e135afbe858ad03882d826feaf0124e972d08d",
        },
      });
      const json = await response.json();
      if (json) {
        this.setState({ video: json });
      }
    } catch (err) {
      console.log(err);
    }
  }

  changeSound = () => {
    const video = document.querySelector("video");

    if (video.muted) {
      video.muted = false;
    } else {
      video.muted = true;
    }
  };

  scrollToTop = () => {
    // document.body.scrollTop = 0; // For Safari
    // document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  componentDidMount() {
    this.loadHeroBlockData();
    this.loadVideo();

  }

  createMarkup = (val) => {
    return { __html: val };
  };

  scrollToHeroblock = () => {
    window.scrollTo({
      top: 757,
      behavior: "smooth",
    });
  };

  render() {
    let heroBlockData = this.state.heroBlockData
      ? this.state.heroBlockData[0][this.context.language]
      : null;
    let videoData = this.state.video;

    return (
      <div>
        <div className="welcome">
          <div className="videowrapper">
            {videoData ? (
              <video
                id="home-video"
                width="100%"
                height="100%"
                autoPlay
                muted
                loop
                playsInline
              >
                <source src={videoData[0].en.video} type="video/mp4" />
              </video>
            ) : null}
          </div>

          <div className="overlay" onClick={this.changeSound}></div>

          <div className="welcome__overlay-menu">
            <OverlayMenu />
          </div>

          <div
            className="scroll-down-button overlay__text"
            onClick={this.scrollToHeroblock}
          >
            <div></div>
            <div>SCROLL</div>
          </div>
        </div>
        {/* hero block */}
        <div className="description-container">
          <div className="description container-sm">
            <div className="description__img">
              <div
                className="description__img-img"
                style={{
                  backgroundImage: `url(${this.state.heroBlockData?.[0].en.image})`,
                }}
              ></div>
              <div className="description__overlay-text-wrapper">
                <h3 data-content={heroBlockData?.title}>
                  {heroBlockData?.title}

                </h3>
                <div
                  className={`description__text description__text-web ${window.safari !== undefined ? "description__safari" : ""
                    }`}
                >
                  <div style={{ marginBottom: "40px" }}>{heroBlockData?.subtitle}</div>

                  <span className="description__next-button">
                    <Link to="/aboutus" className="magnetic">
                      <div className="arrow-magnetic-button">
                        <Outline />
                        <span className="lnr lnr-arrow-right"></span>
                      </div>
                    </Link>
                  </span>
                </div>

              </div>





            </div>

            <div className="description__text description__text-mobile">
              <div>{heroBlockData?.subtitle}</div>

              <Link to="/aboutus" style={{marginTop: "38px"}}>
                {this.context.language === "en" ? "View more" : "იხილეთ მეტი"}
                <span className="lnr lnr-arrow-right" ></span>
              </Link>
            </div>
          </div>
        </div>

        {/* end of hero block */}

        <div className="banner-container">
          <Banner />
        </div>

        <Gallery />
        <Events />
        <Living order={0} />
        <Living order={1} />

        <div
          className="home__scrollup"
          style={{
            height: "150px",
            width: "100%",
            backgroundColor: "white",
            display: "flex",
            flexDirection: "row-reverse",
          }}
        >
          <span
            className="description__next-button"
            onClick={this.scrollToTop}
            style={{
              marginRight: "70px",
              marginTop: "5px",
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <div className="arrow-magnetic-button magnetic">
              <Outline />
              <span
                className="lnr lnr-arrow-right"
                style={{ transform: "rotate(270deg)" }}
              ></span>
            </div>
          </span>
        </div>
      </div>
    );
  }
}

export default Home;
