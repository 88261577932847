import React from "react";
import { LangContext } from "../../../languageContext";
import "./living.scss";
import RelatedProducts from "../related-products/related-products";

class Living extends React.Component {
  static contextType = LangContext;

  constructor(props) {
    super(props);

    this.state = {
      sectionData: null,
    };
  }
  componentDidMount = () => {
    this.loadSectionData();
  };

  async loadSectionData() {
    try {
      const response = await fetch(
        "https://n1.noxtton.com/motif-home-section",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-api-key": "e17fd67932e135afbe858ad03882d826feaf0124e972d08d",
          },
        }
      );
      const json = await response.json();

      if (json) {
        this.setState({ sectionData: json });
      }
    } catch (err) {
      console.log(err);
    }
  }

  createMarkup = (val) => {
    return { __html: val };
  };

  render() {
    let sectionData =
      this.state.sectionData && this.state.sectionData[this.props.order]
        ? this.state.sectionData[this.props.order][this.context.language]
        : null;

    return (
      <div className="living">
        <div className="living__hat">
          <div
            className="living__img"
            style={{
              backgroundImage: `url(${
                this.state.sectionData &&
                this.state.sectionData[this.props.order]?.en.backgroundImage
              })`,
            }}
          ></div>
        </div>

        <div className="living__text">
          <div>{sectionData?.title}</div>
          <div
            dangerouslySetInnerHTML={this.createMarkup(sectionData?.subtitle)}
          ></div>
        </div>

        <RelatedProducts order={this.props.order} />
      </div>
    );
  }
}

export default Living;
