import React from "react";
import { LangContext } from "../../../languageContext";
import "./events.scss";

class Events extends React.Component {
  static contextType = LangContext;

  constructor(props) {
    super(props);
    this.state = {
      data: null,
      hover: false,
      img: "",
    };
  }

  mouseOver = () => {
    this.setState({ hover: true });
  };

  mouseOut = () => {
    this.setState({ hover: false });
  };

  componentDidMount = () => {
    this.loadData();
  };

  async loadData() {
    try {
      const response = await fetch("https://n1.noxtton.com/motif-news", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-api-key": "e17fd67932e135afbe858ad03882d826feaf0124e972d08d",
        },
      });
      const json = await response.json();
      if (json) {
        this.setState({ data: json });
      }
    } catch (err) {
      console.log(err);
    }
  }

  createMarkup = (val) => {
    return { __html: val };
  };

  render() {
    let data = this.state.data ? this.state.data : null;

    return (
      <div className="events">
        <div className="events__heading" disabled>
          {this.context.language === "en"
            ? "EVENTS AND NEWS"
            : "სიახლეები და ღონისძიებები"}
        </div>

        <div
          className="events__main"
          onMouseOver={this.mouseOver.bind(this)}
          onMouseLeave={this.mouseOut.bind(this)}
        >
          {this.state.hover ? (
            <div
              className="events__thumbnail"
              style={{ backgroundImage: `url(${this.state.img})` }}
            ></div>
          ) : null}

          <a
            target="_blank"
            rel="noreferrer"
            href={
              this.context.language === "en"
                ? data?.[0].en.link
                : data?.[0].ka && data?.[0].ka.link
                ? data?.[0].ka.link
                : data?.[0].en.link
            }
            className="events__link"
          >
            <div
              className="events__detail first external-link"
              onMouseOver={() => this.setState({ img: data?.[0].en.banner })}
            >
              <div className="events__date">
                <div>{data?.[0][this.context.language].date}</div>
              </div>
              <div className="events__text">
                <div className="events__name">
                  {this.context.language === "en"
                    ? data?.[0].en.title.toUpperCase()
                    : data?.[0].ka.title}
                </div>
                <section
                  className="events__name-section"
                  dangerouslySetInnerHTML={this.createMarkup(
                    data?.[0][this.context.language].subtitle
                  )}
                ></section>
              </div>
            </div>
          </a>

          <a
            target="_blank"
            rel="noreferrer"
            href={
              this.context.language === "en"
                ? data?.[1].en.link
                : data?.[1].ka && data?.[1].ka.link
                ? data?.[1].ka.link
                : data?.[1].en.link
            }
            className="events__link"
          >
            <div
              className="events__detail"
              onMouseOver={() => this.setState({ img: data?.[1].en.banner })}
            >
              <div className="events__date">
                <div>{data?.[1][this.context.language].date}</div>
              </div>
              <div className="events__text">
                <div className="events__name">
                  {this.context.language === "en"
                    ? data?.[1].en.title.toUpperCase()
                    : data?.[1].ka.title}
                </div>
                <section
                  className="events__name-section"
                  dangerouslySetInnerHTML={this.createMarkup(
                    data?.[1][this.context.language].subtitle
                  )}
                ></section>
              </div>
            </div>
          </a>

          <a
            target="_blank"
            rel="noreferrer"
            href={
              this.context.language === "en"
                ? data?.[2].en.link
                : data?.[2].ka && data?.[2].ka.link
                ? data?.[2].ka.link
                : data?.[2].en.link
            }
            className="events__link"
          >
            <div
              className="events__detail"
              onMouseOver={() => this.setState({ img: data?.[2].en.banner })}
            >
              <div className="events__date">
                <div>{data?.[2][this.context.language].date}</div>
              </div>
              <div className="events__text">
                <div className="events__name">
                  {this.context.language === "en"
                    ? data?.[2].en.title.toUpperCase()
                    : data?.[2].ka.title}
                </div>
                <section
                  className="events__name-section"
                  dangerouslySetInnerHTML={this.createMarkup(
                    data?.[2][this.context.language].subtitle
                  )}
                ></section>
              </div>
            </div>
          </a>
        </div>
      </div>
    );
  }
}

export default Events;
