import React from "react";
import { Link } from "react-router-dom";
// import {ReactComponent as NextButton} from '../../assets/components/cursor-next.svg';
import "./installation.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { LangContext } from "../../languageContext";
import { ReactComponent as Outline } from "../../assets/components/outline.svg";

const slides = [
  {
    id: 1,
  },
  {
    id: 2,
  },
  {
    id: 3,
  },
];

export default class Installation extends React.Component {
  static contextType = LangContext;

  settings = {
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    arrows: false,
    afterChange: (index) => {
      this.setState({ currentSlide: index });
    },
  };

  state = {
    currentSlide: 0,
    data: null,
    imagesAdded: false,
  };

  componentDidMount = () => {
    this.getInsData();
  };

  async getInsData() {
    try {
      const response = await fetch(
        "https://n1.noxtton.com/motif-installation-delivery",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-api-key": "e17fd67932e135afbe858ad03882d826feaf0124e972d08d",
          },
        }
      );
      const json = await response.json();
      this.setState({ data: json }, this.createSlides);
    } catch (err) {
      console.log(err);
    }
  }

  createSlides = () => {
    if (this.state.data) {
      let data = this.state.data[0].en;
      slides[0].src = data?.gallery1Full;
      slides[1].src = data?.gallery2Full;
      slides[2].src = data?.gallery3Full;
    }
    this.setState({ imagesAdded: true });
  };

  createMarkup = (val) => {
    return { __html: val };
  };

  render() {
    const val = this.state.data
      ? this.state.data[0][this.context.language]
      : null;

    return (
      <div className="installation">
        <div className="installation__text">
          <div className="site-path services__site-path">
            <span>
              <Link to="/">
                {this.context.language === "en" ? "Home" : "მთავარი გვერდი"}
              </Link>{" "}
              / {val?.title}
            </span>
          </div>
          <div className="installation__name">{val?.title}</div>
          <div
            className="installation__story"
            dangerouslySetInnerHTML={this.createMarkup(val?.text)}
          ></div>
          {/* <div>
            <a href={`${this.state.data?.[0].installationLink}`} className="learn-more">
            {this.context.language === "en" ? 'Learn more' : "გაიგეთ მეტი"}<i className="lnr lnr-arrow-right"></i>
            </a>
          </div> */}
        </div>

        <div className="installation__gallery">
          <div className="installation__inner-gallery">
            {this.state.imagesAdded ? (
              <>
                <Slider
                  ref={(slider) => (this.slider = slider)}
                  {...this.settings}
                >
                  {slides.map((item) => (
                    <React.Fragment key={item.id}>
                      <div
                        className="installation__gallery-item"
                        style={{ backgroundImage: `url('${item.src}')` }}
                      ></div>
                    </React.Fragment>
                  ))}
                </Slider>
                <div className="slides-num">
                  <span>
                    {this.state.currentSlide + 1} / {slides.length}
                  </span>
                </div>

                <div className="slider-prev-next-buttons">
                  <div
                    className="slider-prev-button"
                    style={{
                      visibility:
                        this.state.currentSlide > 0 &&
                        this.state.currentSlide < slides.length
                          ? "visible"
                          : "hidden",
                    }}
                    onClick={() => {
                      this.slider.slickPrev();
                    }}
                  >
                    <div className="magnetic arrow-magnetic-button special-magnetic">
                      <Outline />
                      <span className="lnr lnr-arrow-right"></span>
                    </div>
                  </div>
                  <div
                    className="slider-next-button"
                    style={{
                      visibility:
                        this.state.currentSlide + 1 < slides.length
                          ? "visible"
                          : "hidden",
                    }}
                    onClick={() => {
                      this.slider.slickNext();
                    }}
                  >
                    <div className="magnetic arrow-magnetic-button special-magnetic">
                      <Outline />
                      <span className="lnr lnr-arrow-right"></span>
                    </div>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
