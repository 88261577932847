import React from "react";
import { Link } from "react-router-dom";
import { LangContext } from "../../languageContext";
import "./contact.scss";

export default class Contact extends React.Component {
  static contextType = LangContext;

  state = {
    data: null,
  };

  componentDidMount = () => {
    this.getContactData();
  };

  async getContactData() {
    try {
      const response = await fetch(
        "https://n1.noxtton.com/motif-contact-and-hero-block",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-api-key": "e17fd67932e135afbe858ad03882d826feaf0124e972d08d",
          },
        }
      );
      const json = await response.json();
      this.setState({ data: json });
    } catch (err) {
      console.log(err);
    }
  }

  createMarkup = (val) => {
    return { __html: val };
  };

  handleSubmit(e) {
    setTimeout(() => {
      e.target.reset();
    }, 1000);
  }

  render() {
    const val = this.state.data
      ? this.state.data[0][this.context.language]
      : null;

    return (
      <div className="contact-page container-sm">
        <div className="site-path">
          <span>
            <Link to="/">
              {this.context.language === "en" ? "Home" : "მთავარი გვერდი"}
            </Link>{" "}
            / {this.context.language === "en" ? "Contact" : "კონტაქტი"}
          </span>
        </div>

        <div className="contact-page__img">
          <div
            className="contact-page__img-img"
            style={{
              backgroundImage: `url('${this.state.data?.[0].en.image}')`,
            }}
          ></div>
          <div className="get-in-touch">
            <h3
              className="get-in"
              data-content={val?.overlaytitle1}
              dangerouslySetInnerHTML={this.createMarkup(val?.overlaytitle1)}
            ></h3>
            {val?.overlaytitle2.length && (
              <h3
                className="touch"
                dangerouslySetInnerHTML={this.createMarkup(val?.overlaytitle2)}
              ></h3>
            )}
          </div>
        </div>

        <div className="contact-page__text">
          <div className="contact-page__shops contact-page__container">
            <div className="contact-page__labels">
              {this.context.language === "en"
                ? "Our showrooms"
                : "ჩვენი შოურუმები"}
            </div>
            <div className="contact-page__address">
              <div className="contact-page__address-first">
                <div className="contact__address">
                  <div
                    dangerouslySetInnerHTML={this.createMarkup(
                      val?.address1line1
                    )}
                  ></div>
                  <div
                    dangerouslySetInnerHTML={this.createMarkup(
                      val?.address1line2
                    )}
                  ></div>
                </div>
                <div className="contact-page__small-underline"></div>
                <div className="contact__phone">
                  <div
                    dangerouslySetInnerHTML={this.createMarkup(
                      this.state.data?.[0].en.address1phonenumber
                    )}
                  ></div>
                  <div
                    dangerouslySetInnerHTML={this.createMarkup(
                      this.state.data?.[0].en.address1email
                    )}
                  ></div>
                </div>
              </div>
              <div className="contact-page__address-second">
                <div className="contact__address">
                  <div
                    dangerouslySetInnerHTML={this.createMarkup(
                      val?.address2line1
                    )}
                  ></div>
                  <div
                    dangerouslySetInnerHTML={this.createMarkup(
                      val?.address2line2
                    )}
                  ></div>
                </div>

                <div className="contact-page__small-underline"></div>
                <div className="contact__phone">
                  <div
                    dangerouslySetInnerHTML={this.createMarkup(
                      this.state.data?.[0].en.address2phonenumber
                    )}
                  ></div>
                  <div
                    dangerouslySetInnerHTML={this.createMarkup(
                      this.state.data?.[0].en.address2email
                    )}
                  ></div>
                </div>
              </div>

              <div className="contact-page__address-third">
                <div className="contact__address">
                  <div
                    dangerouslySetInnerHTML={this.createMarkup(
                      val?.address3line1
                    )}
                  ></div>
                  <div
                    dangerouslySetInnerHTML={this.createMarkup(
                      val?.address3line2
                    )}
                  ></div>
                </div>

                <div className="contact-page__small-underline"></div>
                <div className="contact__phone">
                  <div
                    dangerouslySetInnerHTML={this.createMarkup(
                      this.state.data?.[0].en.address3phonenumber
                    )}
                  ></div>
                  <div
                    dangerouslySetInnerHTML={this.createMarkup(
                      this.state.data?.[0].en.address3email
                    )}
                  ></div>
                </div>
              </div>
            </div>
          </div>

          {/* Begin Mailchimp Signup Form */}
          <div
            id="mc_embed_signup"
            className="contact-page__email contact-page__container"
          >
            <form
              action="https://motif.us1.list-manage.com/subscribe/post?u=518e24fcb99095851fa4ae36c&amp;id=4c14eb3e0a"
              method="post"
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              className="validate"
              target="_blank"
              noValidate
              onSubmit={this.handleSubmit}
            >
              <div className="contact-page__labels">
                {this.context.language === "en"
                  ? "Newsletter"
                  : "გამოიწერეთ სიახლეები"}
              </div>
              <div
                id="mc_embed_signup_scroll"
                className="contact-page__email-wrapper"
              >
                <div className="contact-page__input-data mc-field-group">
                  <input
                    type="email"
                    name="EMAIL"
                    id="mce-EMAIL"
                    style={{ outline: "none !important" }}
                    onChange={(e) => {
                      e.target.value.length
                        ? e.target.classList.add("dirty")
                        : e.target.classList.remove("dirty");
                    }}
                  />
                  <div className="contact-page__email-underline"></div>
                  <label>
                    {this.context.language === "en" ? "Email" : "ელ-ფოსტა"}
                  </label>

                  <button
                    type="submit"
                    value="Subscribe"
                    name="subscribe"
                    id="mc-embedded-subscribe"
                    className="magnetic footer-email-button"
                  >
                    <span className="lnr lnr-arrow-right"></span>
                  </button>
                </div>
                <div id="mce-responses" className="clear">
                  <div
                    className="response"
                    id="mce-error-response"
                    style={{ display: "none" }}
                  ></div>
                  <div
                    className="response"
                    id="mce-success-response"
                    style={{ display: "none" }}
                  ></div>
                </div>
                {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
                <div
                  style={{ position: "absolute", left: "-5000px" }}
                  aria-hidden="true"
                >
                  <input
                    type="text"
                    name="b_518e24fcb99095851fa4ae36c_4c14eb3e0a"
                    tabIndex="-1"
                    value=""
                  />
                </div>
                <div className="clear"></div>
              </div>
            </form>
          </div>

          {/* End mc_embed_signup */}

          <div className="contact-page__media contact-page__container">
            <div className="contact-page__labels">
              {this.context.language === "en" ? "Follow us" : "გამოგვყევით"}
            </div>
            <div className="contact-page__media-wrapper">
              <div>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`${this.state.data?.[0].en.Link1}`}
                >
                  {this.state.data?.[0].en.Link1name}
                </a>
              </div>
              <div>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`${this.state.data?.[0].en.Link2}`}
                >
                  {this.state.data?.[0].en.Link2name}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
